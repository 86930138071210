<template>
    <b-modal
      :width="1200"
      no-close-on-esc
      :active.sync="FinishAniBool"
      no-close-on-backdrop
      :can-cancel="false"
    >
       <div class="modal-content"> 
        <!-- <button @click="test(valeurX, valeurY, fcMoyenne, ccMoyenne, couleurCCMoyenne, valeurCCX, valeurCCY, DefaultOpenedDetails, TimeCalibrage, elapsedExerciseTime)">Test</button> -->
        <appelRelectureBiofeedback
          :valeurX="valeurX"
          :fcMoyenne="fcMoyenne"
          :ccMoyenne="ccMoyenne"
          :couleurCCMoyenne="couleurCCMoyenne"
          :TabRowFC="valeurY"
          :DefaultOpenedDetails="DefaultOpenedDetails"
          :ValeurCCX="valeurCCX"
          :TabRowCC="valeurCCY"
          :labelChrono="TimeCalibrage"
          :timeElapsed="elapsedExerciseTime"
          :valeurRouge="valeurRouge"
          :valeurVerte="valeurVerte"
          :valeurOrange="valeurOrange"
          @commentaire-change="handleCommentaireChange"
        />
        <div class="action-buttons">
          <button
            @click="handleSaveData"
            id="buttonSave"
            class="buttonChart save-button"
          >
            Sauvegarder
          </button>
          <button
            @click="handleCancel"
            id="buttonAnnuler"
            class="buttonChart cancel-button"
          >
            Annuler
          </button>
        </div>
      </div> 
    </b-modal>
  </template>
  
  <script>
  import appelRelectureBiofeedback from "@/components/chart/appelRelectureBiofeedback.vue";

  export default {
    data() {
      return {
        isCardRedirectFront: false,
      };
    },
    components: {
      appelRelectureBiofeedback,
    },
    methods: {
      handleCommentaireChange(commentaire) {
        // Logique de mise à jour du commentaire
        // Exemple : this.$emit('commentaire-change', commentaire);
        this.$emit("commentaire-change", commentaire);
      },
      handleSaveData() {
        // Logique de sauvegarde des données
        // Exemple : this.$emit('save-data');
        this.$emit("save-data");
      },
      handleCancel() {
        this.FinishAniBool = false;
        this.refreshPage();
      },
      refreshPage() {
        window.location.reload();
      },
      test(valeurX, valeurY, fcMoyenne, ccMoyenne, couleurCCMoyenne, valeurCCX, valeurCCY, DefaultOpenedDetails, TimeCalibrage, elapsedExerciseTime) {
        console.log(valeurX, valeurY, fcMoyenne, ccMoyenne, couleurCCMoyenne, valeurCCX, valeurCCY, DefaultOpenedDetails, TimeCalibrage, elapsedExerciseTime);
      },
      
    },
    props: {
      valeurX: {
        type: Array,
        default: () => [],
      },
      valeurY: {
        type: Array,
        default: () => [],
      },
      fcMoyenne: {
        type: Number,
        default: 0,
      },
      ccMoyenne: {
        type: Number,
        default: 0,
      },
      couleurCCMoyenne: {
        type: String,
        default: "",
      },
      valeurCCX: {
        type: Array,
        default: () => [],
      },
      valeurCCY: {
        type: Array,
        default: () => [],
      },
      DefaultOpenedDetails: {
        type: String,
      },
      TimeCalibrage: {
        type: Number,
        default: 0,
      },
      elapsedExerciseTime: {
        default: 0,
      },
      FinishAniBool: {
        type: Boolean,
        default: false,
      },
      valeurRouge:
      {
        type: Number,
        default: 0,
      },
      valeurVerte:
      {
        type: Number,
        default: 0,
      },
      valeurOrange:
      {
        type: Number,
        default: 0,
      },
    },
  };
  </script>
  
  <style scoped>
  .modal-content {
    background-color: white !important;
    border-radius: 20px;
    padding: 20px;
  }
  
  .action-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .buttonChart {
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .save-button {
    background-color: rgb(35, 147, 128);
  }
  
  .cancel-button {
    background-color: gray !important;
  }
  </style>
  