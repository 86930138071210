import Parse from "parse";

function formatDuration(duration) {
  if (typeof duration !== "number" || isNaN(duration)) {
    return "00:00";
  }

  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");
  return `${formattedMinutes}:${formattedSeconds}`;
}

function isDurationFormatted(duration) {
  return typeof duration === "string";
}

export async function getExercicesRessources(BriqueSelected) {
  // Traitement pour la prod
  let ModuleByBriqueSelected;
  if (
    BriqueSelected.protocoles[3] &&
    BriqueSelected.protocoles[3].modules.length > 0
  ) {
    ModuleByBriqueSelected = BriqueSelected.protocoles[3].modules;
  } else {
    ModuleByBriqueSelected = BriqueSelected.protocoles[2].modules;
  }

  const ModuleByBriqueSelectedId = ModuleByBriqueSelected.map(module => module.id);

  const object = Parse.Object.extend("link");
  const query = new Parse.Query(object);

  query.containedIn("parent_folder", ModuleByBriqueSelectedId);
  query.include("composite_file");
  query.include("composite_file.jeux.game_type");
  query.include("parent_folder");
  const links = await query.find();

  // crée un tableau récupérant chaque id de parent folder et chaque id du composite file dans un objet
  let linksId = [];
  links.forEach((link) => {
    if (link.get("composite_file")) {
      linksId.push({
        parent_folder: link.get("parent_folder").id,
        composite_file: link.get("composite_file").id,
        order: link.attributes.order,
      });
    }
  });

  // Utilisation de Sets pour éviter les doublons
  const shortExercisesSet = new Set();
  const longExercisesSet = new Set();
  const moduleExpertSet = new Set();
  const jeuxSet = new Set();

  let shortExercises = [];
  let longExercises = [];
  let moduleExpertExercises = [];
  let jeuxExercises = [];

  links.forEach((link) => {
    if (!link.get("composite_file")) {
      return;
    }

    const compositeFile = link.get("composite_file");
    const linkIdObj = linksId.find(
      (obj) => obj.composite_file === compositeFile.id
    );

    if (compositeFile) {
      const shortFile = compositeFile.get("short_file");
      if (shortFile && !shortExercisesSet.has(shortFile.id)) {
        if (!isDurationFormatted(shortFile.get("duration"))) {
          shortFile.set("duration", formatDuration(shortFile.get("duration")));
        }
        shortFile.set("parent_folder_id", linkIdObj.parent_folder);
        shortFile.set("order", linkIdObj.order);
        shortExercises.push(shortFile);
        shortExercisesSet.add(shortFile.id);
      }

      const longFile = compositeFile.get("long_file");
      if (longFile && shortFile && longFile.id !== shortFile.id && !longExercisesSet.has(longFile.id)) {
        if (!isDurationFormatted(longFile.get("duration"))) {
          longFile.set("duration", formatDuration(longFile.get("duration")));
        }
        longFile.set("parent_folder_id", linkIdObj.parent_folder);
        longFile.set("order", linkIdObj.order);
        longExercises.push(longFile);
        longExercisesSet.add(longFile.id);
      }

      const moduleExpert = compositeFile.get("moduleExpert");
      if (moduleExpert && !moduleExpertSet.has(moduleExpert.id)) {
        moduleExpert.set("parent_folder_id", linkIdObj.parent_folder);
        moduleExpertExercises.push(moduleExpert);
        moduleExpertSet.add(moduleExpert.id);
      }

      const jeux = compositeFile.get("jeux");
      if (jeux && !jeuxSet.has(jeux.id)) {
        const gameType = jeux.get("game_type");
        if (gameType) {
          jeux.set("game_type", gameType);
        }
        jeux.set("parent_folder_id", linkIdObj.parent_folder);
        jeuxExercises.push(jeux);
        jeuxSet.add(jeux.id);
      }
    }
  });

  if (shortExercises.length > 0) {
    shortExercises.sort((a, b) => a.attributes.order - b.attributes.order);
  }
  if (longExercises.length > 0) {
    longExercises.sort((a, b) => a.attributes.order - b.attributes.order);
  }

  return {
    shortExercises,
    longExercises,
    moduleExpertExercises,
    jeuxExercises,
  };
}
