<template>
  <div>
    <!-- <audio>
      <source :src="'https://www.symbiocenter.com/assets/audio/' + sourceAudio" />
    </audio> -->
    <div id="audioplayer" class="audio-player" style="margin-top: -10px">
      <audio
        @timeupdate="updateCurrentTime"
        ref="audio"
        v-if="audioMoniteurPlay"
        id="audio_exo"
        class="audio_exo"
        :src="'https://www.symbiocenter.com/assets/audio/' + sourceAudio"
        autoplay
        @ended="AudioEnd"
      ></audio>
      <audio
        @timeupdate="updateCurrentTime"
        ref="audio"
        v-else
        id="audio_exo"
        class="audio_exo"
        :src="'https://www.symbiocenter.com/assets/audio/' + sourceAudio"
        @ended="AudioEnd"
      ></audio>
      <div class="controls">
        <div class="containerAudio">
          <!-- <div class="stopAudio">
            <button class="close-button" title="Close" @click="emitClose">
              <svg width="32"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="#239380"
              >
                <path
                  fill="#239380"
                  d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                />
                <path
                  fill="#239380"
                  d="M14.293 14.293a.999.999 0 01-1.414 0L10 11.414l-2.879 2.879a.999.999 0 11-1.414-1.414L8.586 10 5.707 7.121a.999.999 0 111.414-1.414L10 8.586l2.879-2.879a.997.997 0 011.414 0 .999.999 0 010 1.414L11.414 10l2.879 2.879a.999.999 0 010 1.414z"
                />
              </svg>
            </button>
          </div> -->
          <div class="playAudio">
            <button class="player-button">
              <svg
                v-if="audioMoniteur"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="#239380"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div class="volumeAudio">
            <button class="sound-button" v-if="!isFinish">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="#239380"
              >
                <path
                  fill-rule="evenodd"
                  d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <input
              v-if="!isFinish"
              type="range"
              id="volume-slider"
              max="100"
              value="100"
              name="VolumeSlider"
            />
            <output id="volume-output" style="display: none">100</output>
          </div>
          <div v-if="audioMoniteur" class="titleAudio">
            <p>
              <!-- <span v-if="indexAudio">{{ indexAudio }}</span> -->
              <!-- <span class="titleAudios">{{" "+ titleAudio + " - " }}</span -->
              <span id="currentTime"></span> / <span id="duration"></span>
            </p>
          </div>
          <div v-else class="titleAudio">
            <p>{{ titleAudio }}</p>
          </div>
          <div v-if="audioMoniteur" class="Personnalisation">
            <button
              id="buttonPersonnalisatClose"
              class="buttonPersonnalisatClose"
              v-if="isFinish"
              v-on:click="
                (showPersonnalisationExercice = !showPersonnalisationExercice),
                  callData(),
                  resetDiapo()
              "
            >
              Fermer le diaporama
            </button>
            <button
              v-else
              :class="
                screenWidth < 950 ? 'buttonPerso' : 'buttonPersonnalisation'
              "
              v-on:click="
                (showPersonnalisationExercice = !showPersonnalisationExercice),
                  callData(),
                  resetDiapo()
              "
              id="buttonPersonnalisation"
            >
              <span class="textPerso">Personnalisation</span
              ><span class="iconePerso"
                ><svg
                  height="30px"
                  width="25px"
                  version="1.1"
                  id="_x32_"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 512.00 512.00"
                  xml:space="preserve"
                  fill="#489182"
                  stroke=""
                  stroke-width="0.00512"
                  transform="rotate(0)matrix(1, 0, 0, 1, 0, 0)"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke="#CCCCCC"
                    stroke-width="72.704"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <g>
                      <path
                        class="st0"
                        d="M500.781,224.245L447,211.561c-4.93-21.263-13.289-41.202-24.509-59.193l29.053-46.992 c3.552-5.763,2.685-13.184-2.097-17.965l-24.86-24.86c-4.772-4.772-12.202-5.64-17.956-2.088L359.64,89.517 C341.648,78.298,321.719,69.929,300.456,65l-12.693-53.78C286.211,4.649,280.334,0,273.588,0h-35.166 c-6.755,0-12.623,4.649-14.176,11.219L211.553,65c-21.263,4.93-41.194,13.29-59.175,24.509l-47.001-29.053 c-5.745-3.544-13.193-2.675-17.956,2.088L62.553,87.412c-4.781,4.78-5.641,12.211-2.088,17.956l29.044,47 C78.289,170.36,69.929,190.289,65,211.552l-53.772,12.684C4.657,225.798,0,231.676,0,238.412v35.166 c0,6.763,4.649,12.623,11.228,14.175L65,300.447c4.93,21.264,13.299,41.194,24.518,59.185l-29.053,46.991 c-3.544,5.746-2.684,13.202,2.08,17.965l24.868,24.86c4.781,4.789,12.21,5.64,17.965,2.087l47.001-29.044 c17.982,11.22,37.912,19.579,59.175,24.508l12.692,53.781c1.553,6.58,7.421,11.22,14.176,11.22h35.166 c6.746,0,12.623-4.64,14.175-11.22l12.693-53.781c21.254-4.93,41.192-13.288,59.176-24.508l47,29.053 c5.754,3.552,13.184,2.684,17.956-2.096l24.869-24.86c4.772-4.772,5.64-12.21,2.088-17.956l-29.053-47 c11.22-17.983,19.579-37.921,24.509-59.176l53.781-12.693c6.588-1.561,11.219-7.429,11.219-14.184V238.42 C512,231.666,507.36,225.798,500.781,224.245z M256,390.614c-74.334,0-134.606-60.272-134.606-134.614 c0-74.342,60.272-134.615,134.606-134.615c74.359,0,134.623,60.272,134.623,134.615C390.623,330.342,330.359,390.614,256,390.614z"
                      ></path>
                      <path
                        class="st0"
                        d="M256,170.684c-47.114,0-85.316,38.194-85.316,85.316s38.202,85.315,85.316,85.315 c47.123,0,85.316-38.192,85.316-85.315S303.123,170.684,256,170.684z M256,297.859c-23.114,0-41.86-18.746-41.86-41.859 c0-23.114,18.746-41.86,41.86-41.86c23.114,0,41.859,18.746,41.859,41.86C297.859,279.114,279.114,297.859,256,297.859z"
                      ></path>
                    </g>
                  </g></svg
              ></span>
            </button>
          </div>

          <div class="AudioTimer">
            <input type="range" class="timeline" max="100" value="0" />
          </div>
          <div></div>
        </div>
      </div>
    </div>

    <div class="titleMobile">
      <!-- <p>{{ titleAudio }}</p> -->
    </div>
    <div
      v-if="showPersonnalisationExercice"
      :class="{ bubble: !isFinish, bubbleTable: !isFinish }"
      style=""
    >
      <button
        v-if="isFinish == false"
        v-on:click="
          showPersonnalisationExercice = !showPersonnalisationExercice
        "
        style="margin-left: auto; margin-right: 1%; margin-top: 1%"
        type="button"
        class="btn-close"
      >
        <span class="icon-cross"></span>
        <span class="visually-hidden"></span>
      </button>

      <div class="PlacementDiapo">
        <div
          v-if="isDiapo"
          v-for="slide in element_slideshow"
          :key="slide.id"
          :style="{ float: 'left', margin: '1.5%' }"
          @click="recupIdDiapo(slide.id)"
        >
          <div v-if="slide.attributes.imgSlideshow">
            <img
              class="imgDiapo"
              :src="
                'https://www.symbiocenter.com/assets/vignette_diaporama/' +
                slide.attributes.name +
                '.jpg'
              "
              width="100px"
            />
            <br />
            {{ slide.attributes.name }}
          </div>
        </div>
      </div>

      <div class="music-container">
        <div
          v-if="isMusique"
          v-for="music in element_music"
          :key="music.id"
          class="music-item"
          @click="recupMusique(music)"
        >
          <img
            class="imgDiapo"
            :src="
              'https://www.symbiocenter.com/assets/musique_img/' +
              music.attributes.name +
              '.jpg'
            "
            width="100px"
          />
          <br />
          {{ music.attributes.name }}
        </div>
      </div>
      <div v-if="isFinish">
        <template>
          <div id="carouseldiv" style="display: flex; justify-content: center">
            <button
              class="buttonPersonnalisationss"
              v-on:click="
                (showPersonnalisationExercice = !showPersonnalisationExercice),
                  callData(),
                  resetDiapo()
              "
            >
              Fermer le diaporama
            </button>
            <div class="carousel-container">
              <div class="action-bar">
                <button @click="togglePlayPause" class="action-btn">
                  <i class="fas fa-pause" v-if="isPlaying"></i>
                  <i class="fas fa-play" v-else></i>
                </button>
                <div class="volume-control">
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.1"
                    v-model="volume"
                    @input="updateVolume"
                    class="volume-slider"
                  />
                  <button @click="isMuted = !isMuted" class="action-btn">
                    <i class="fas fa-volume-up" v-if="!isMuted"></i>
                    <i class="fas fa-volume-mute" v-else></i>
                  </button>
                </div>
                <div class="volume-control">
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.1"
                    v-model="volumeMusic"
                    @input="updateVolumeMusic"
                    class="volume-slider"
                  />
                  <button
                    @click="isMutedMusic = !isMutedMusic"
                    class="action-btn"
                  >
                    <i class="fas fa-music" v-if="!isMutedMusic"></i>
                    <i class="fas fa-volume-mute" v-else></i>
                  </button>
                </div>
                <button @click="fullscreenAudio" class="action-btn">
                  <i class="fas fa-expand" v-if="!isFullscreen"></i>
                  <i class="fas fa-times" v-else></i>
                </button>
              </div>
              <owl-carousel
                id="bCarouselAudio"
                class="disable-mouse owl-theme"
                v-model="carouselDiapo"
                :autoplay="autoPlay"
                :autoplay-timeout="interval"
                :loop="repeat"
                :nav="arrow"
                :nav-hover="arrowHover"
                :dots="indicator"
                :items="1"
                :margin="10"
                :stage-padding="0"
                :lazy-load="true"
                :merge="true"
                :animate-in="'animate__animated animate__fadeIn'"
                :animate-out="'animate__animated animate__fadeOut'"
              >
                <div
                  v-for="(element, i) in element_background"
                  :key="i"
                  class="carousel-item"
                >
                  <!-- {{'https://www.symbiocenter.com/assets/images_diaporama/'+ NameOfDiapo + '/' + (i+1) + '.jpg'}} -->

                  <div style="display: flex; justify-content: center">
                    <img
                      id="bcarousel"
                      :src="
                        'https://www.symbiocenter.com/assets/images_diaporama/' +
                        NameOfDiapo +
                        '/' +
                        (i + 1) +
                        '.jpg'
                      "
                      :class="{ 'fullscreen-image': isFullscreen }"
                      style="object-fit: cover; width: 100%; height: 100%"
                    />
                  </div>
                </div>
              </owl-carousel>
            </div>
          </div>
          <audio
            ref="audioMusic"
            controls
            id="audio_musique"
            autoplay
            :src="AudioMusique"
            style="display: none"
            @ended="restartAudio"
          ></audio>
        </template>
      </div>
      <div class="dh-modal" v-if="isOpen">
        <div
          class="dh-modal-overlay dh-modal-toggle"
          @click="toggleModal"
        ></div>
        <div class="dh-modal-wrapper dh-modal-transition">
          <div class="dh-modal-header">
            <h2 class="dh-modal-heading">Prévisualisation</h2>
            <button class="dh-modal-close dh-modal-toggle" @click="toggleModal">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="dh-modal-body">
            <template>
              <section>
                <b-carousel
                  v-model="carousel"
                  :arrow="arrow"
                  :animated="animated"
                  :autoplay="autoPlay"
                  :interval="interval2"
                  :repeat="repeat"
                  :pause-hover="false"
                  :arrowHover="arrowHover"
                  :indicator="indicator"
                >
                  <b-carousel-item
                    v-for="(element, i) in element_background"
                    :key="i"
                  >
                    <img
                      :src="
                        'https://www.symbiocenter.com/assets/images_diaporama/' +
                        NameOfDiapo +
                        '/' +
                        (i + 1) +
                        '.jpg'
                      "
                    />
                  </b-carousel-item>
                </b-carousel>
              </section>
            </template>
          </div>
          <div class="buttonValidate">
            <button
              style="padding-right: 10px"
              class="buttonDiapo"
              v-on:click="validateDiapo()"
            >
              <img src="../assets/img/jaccepte.png" width="20px" />
            </button>
            <button
              style="padding-left: 10px"
              class="buttonDiapo"
              v-on:click="AnnulerDiapo()"
            >
              <img src="../assets/img/annuler.png" width="20px" />
            </button>
          </div>
        </div>
      </div>
      <div class="dh-modal" v-if="isOpen2">
        <div
          class="dh-modal-overlay dh-modal-toggle"
          @click="toggleModal2"
        ></div>
        <div class="dh-modal-wrapper dh-modal-transition">
          <div class="dh-modal-header">
            <h2 class="dh-modal-heading">Prévisualisation</h2>
            <button
              class="dh-modal-close dh-modal-toggle"
              @click="toggleModal2"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="dh-modal-body">
            <img :src="Musique" />
            <audio controls autoplay style="display: none">
              <source :src="AudioMusique" type="audio/mpeg" />
            </audio>
          </div>
          <div class="buttonValidate">
            <button
              style="padding-right: 10px"
              id="buttonDiapo"
              class="buttonDiapo"
              v-on:click="validateMusique()"
            >
              <img src="../assets/img/jaccepte.png" width="20px" />
            </button>
            <button
              style="padding-right: 10px"
              class="buttonDiapo"
              v-on:click="AnnulerMusique()"
            >
              <img src="../assets/img/annuler.png" width="20px" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
import OwlCarousel from "vue-owl-carousel";
import "animate.css";
import "fullscreen-polyfill";
export default {
  name: "AudioPlayer",
  data: () => ({
    tableau_slideshow2: [],
    element_slideshow2: [],
    AudioMusique: "",
    screenWidth: 0,
    minute: 0,
    second: 0,
    hour: 0,
    showPersonnalisationExercice: false,
    tableau_slideshow: [],
    element_slideshow: [],
    tableau_music: [],
    element_music: [],
    tableau_background: [],
    element_background: [],
    ToggleFullScreen: false,
    Diapo: "",
    Musique: "",
    fullscreen: false,
    isOpen: false,
    isOpen2: false,
    isMusique: false,
    isDiapo: true,
    isFinish: false,
    IdDiapo: "",
    numberDiapo: 0,
    carousel: 0,
    audioStart: false,
    animated: "fade",
    drag: false,
    autoPlay: true,
    pauseHover: false,
    pauseInfo: true,
    repeat: true,
    pauseType: false,
    widthPlayer: 0,
    prgress: false,
    arrowBoth: false,
    arrowHover: false,
    arrow: false,
    indicator: false,
    interval: 10000,
    interval2: 3000,
    isFullscreen: false,
    isPaused: false,
    showVolumeSlider: false,
    isPlaying: true,
    isMutedMusic: false,
    carouselDiapo: true,
    volumeMusic: 0.5, // valeur initiale, à ajuster selon vos besoins
    volume: 0.5, // valeur initiale, à ajuster selon vos besoins
    isMuted: false,
    carousels: [
      { title: "Slide 1", color: "dark" },
      { title: "Slide 2", color: "primary" },
      { title: "Slide 3", color: "info" },
      { title: "Slide 4", color: "success" },
      { title: "Slide 5", color: "warning" },
      { title: "Slide 6", color: "danger" },
    ],
    NameOfDiapo: "",
  }),
  components: {
    OwlCarousel,
  },
  props: {
    titleAudio: {
      required: true,
    },
    sourceAudio: {
      required: true,
    },
    timerAudio: {
      required: true,
    },
    audioMoniteur: {
      type: Boolean,
      default: true,
    },
    audioMoniteurPlay: {
      type: Boolean,
      default: true,
    },
    timerForRessources: {
      type: Boolean,
      default: true,
    },
    indexAudio: {
      type: Number,
    },
  },
  computed: {
    playerButton() {
      return document.querySelector(".player-button");
    },
    audio() {
      return document.querySelector(".audio_exo");
    },
    timeline() {
      return document.querySelector(".timeline");
    },
    soundButton() {
      return document.querySelector(".sound-button");
    },
    VolumeSlider() {
      return document.getElementById("volume-slider");
    },
  },
  mounted() {
    // this.widthPlayer = document.getElementById('bCarouselAudio').innerWidth;
    // const contentWidth = myDiv.innerWidth;
    // this.widthPlayer = contentWidth+"px";

    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
    const playerButton = this.playerButton,
      audio = this.audio,
      timeline = this.timeline,
      soundButton = this.soundButton,
      volumeSlider = this.VolumeSlider,
      playIcon = `
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#239380">
      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
    </svg>
        `,
      pauseIcon = `
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#239380">
    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
  </svg>
        `,
      soundIcon = `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#239380">
    <path fill-rule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z" clip-rule="evenodd" />
  </svg>`,
      muteIcon = `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#239380">
    <path fill-rule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM12.293 7.293a1 1 0 011.414 0L15 8.586l1.293-1.293a1 1 0 111.414 1.414L16.414 10l1.293 1.293a1 1 0 01-1.414 1.414L15 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L13.586 10l-1.293-1.293a1 1 0 010-1.414z" clip-rule="evenodd" />
  </svg>`;

    function toggleAudio() {
      if (audio.paused) {
        audio.play();
        playerButton.innerHTML = pauseIcon;
      } else {
        audio.pause();
        playerButton.innerHTML = playIcon;
      }
    }

    if (this.audioMoniteur) {
      const buttonDiapo = document.getElementById("buttonPersonnalisation");
      buttonDiapo.addEventListener("click", function () {
        if (this.showPersonnalisationExercice) {
          // Mettez en pause l'audio seulement si le diaporama est actuellement affiché
          audio.pause();
        }
      });
    }
    if (document.getElementById("buttonDiapo")) {
      const buttonDiapo2 = document.getElementById("buttonDiapo");
      buttonDiapo2.addEventListener("click", function () {
        audio.play();
      });
    }
    if (this.audioMoniteur) {
      let currentTime = document.getElementById("currentTime");
      let duration = document.getElementById("duration");

      audio.addEventListener("loadedmetadata", function () {
        duration.innerHTML = formatTime(audio.duration);
      });

      audio.addEventListener("timeupdate", function () {
        currentTime.innerHTML = formatTime(audio.currentTime);
      });
    }
    function formatTime(time) {
      let minutes = Math.floor(time / 60);
      let seconds = Math.floor(time % 60);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return minutes + ":" + seconds;
    }

    if (audio.ended) {
      this.resetDiapo();
      playerButton.innerHTML = playIcon;
    }
    playerButton.addEventListener("click", toggleAudio);

    const outputContainer = document.getElementById("volume-output");
    volumeSlider.addEventListener("input", (e) => {
      const value = e.target.value;
      outputContainer.textContent = value;
      audio.volume = value / 100;
    });

    function changeTimelinePosition() {
      const percentagePosition = (100 * audio.currentTime) / audio.duration;
      timeline.style.backgroundSize = `${percentagePosition}% 100%`;
      timeline.value = percentagePosition;
    }

    audio.ontimeupdate = changeTimelinePosition;

    function audioEnded() {
      playerButton.innerHTML = playIcon;
    }

    audio.onended = audioEnded;

    function changeSeek() {
      const time = (timeline.value * audio.duration) / 100;
      audio.currentTime = time;
    }

    timeline.addEventListener("change", changeSeek);

    function toggleSound() {
      audio.muted = !audio.muted;
      soundButton.innerHTML = audio.muted ? muteIcon : soundIcon;
    }
    soundButton.addEventListener("click", toggleSound);
    this.$forceUpdate();

    try {
      const sec = parseInt(this.timerAudio, 10); // convert value to number if it's string
      let hours = Math.floor(sec / 3600); // get hours
      let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
      let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
      // add 0 if value < 10; Example: 2 => 02
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return (
        (this.hour = hours), (this.minute = minutes), (this.second = seconds)
      );
    } catch {}
  },
  methods: {
    AudioEnd() {
      //console.log('Audio terminé...')
      this.$emit("audio-finish", true);
    },
    updateCurrentTime() {
      const currentTime = this.$refs.audio.currentTime;
      this.$emit("current-time-update", currentTime);
    },
    restartAudio() {
      const audio = document.getElementById("audio_musique");
      audio.currentTime = 0;
      audio.play();
    },
    emitClose() {
      this.$emit("close");
    },
    togglePlayPause() {
      this.carouselDiapo = !this.carouselDiapo;
      if (this.isPlaying) {
        this.$refs.audio.pause();
        this.$refs.audioMusic.pause();
      } else {
        this.$refs.audio.play();
        this.$refs.audioMusic.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    playPause() {
      var Audio_Musique = document.getElementById("audio_musique");
      if (Audio_Musique.paused) {
        Audio_Musique.play();
      } else {
        Audio_Musique.pause();
      }
      var audio = this.audio,
        playerButton = this.playerButton,
        playIcon = `
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#239380">
      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
    </svg>
        `,
        pauseIcon = `
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#239380">
    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
  </svg>
        `;
      if (audio.paused) {
        audio.play();
        playerButton.innerHTML = pauseIcon;
      } else {
        audio.pause();
        playerButton.innerHTML = playIcon;
      }
    },
    updateVolume() {
      this.$refs.audio.volume = this.volume;
    },
    updateVolumeMusic() {
      this.$refs.audioMusic.volume = this.volumeMusic;
    },
    SoundMusique() {
      var Audio_Musique = document.getElementById("audio_musique");
      const outputContainer = document.getElementById("volume-output");
      var VolumeSlider2 = document.getElementById("volume-slider2");
      VolumeSlider2.addEventListener("input", (e) => {
        const value = e.target.value;
        outputContainer.textContent = value;
        Audio_Musique.volume = value / 100;
      });
    },
    SoundAudio2() {
      const outputContainer2 = document.getElementById("volume-output3");
      var audio_exo = document.querySelector(".audio_exo");
      var VolumeSlider3 = document.getElementById("volume-slider3");
      VolumeSlider3.addEventListener("input", (e) => {
        const value2 = e.target.value;
        outputContainer2.textContent = value2;
        audio_exo.volume = value2 / 100;
      });
    },
    lancerAudio() {
      const audio = document.getElementById("audio_exo");
      audio.play();
    },
    fullscreenAudio() {
      const carouselContainer = document.querySelector(".carousel-container");

      const requestFullscreen = (element) => {
        const method =
          element.requestFullscreen ||
          element.webkitRequestFullScreen ||
          element.mozRequestFullScreen ||
          element.msRequestFullscreen;

        if (method) {
          if (element.webkitRequestFullScreen) {
            method.call(element, Element.ALLOW_KEYBOARD_INPUT);
          } else {
            method.call(element);
          }
        }
      };

      const exitFullscreen = () => {
        const method =
          document.exitFullscreen ||
          document.webkitExitFullscreen ||
          document.mozCancelFullScreen ||
          document.msExitFullscreen;
        if (method) {
          method.call(document);
        }
      };

      const isFullscreen = () => {
        return (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement
        );
      };

      if (!isFullscreen()) {
        requestFullscreen(carouselContainer);
        this.isFullscreen = true;
      } else {
        exitFullscreen();
        this.isFullscreen = false;
      }
    },
    exitFullscreenAudio() {
      const exitFullscreen = () => {
        const method =
          document.exitFullscreen ||
          document.webkitExitFullscreen ||
          document.mozCancelFullScreen ||
          document.msExitFullscreen;
        if (method) {
          method.call(document);
        }
      };

      exitFullscreen();
      this.isFullscreen = false;
    },

    fullscreenChange() {
      document.getElementById(".DiapoFinish").requestFullscreen();
    },

    // recupDiapo(DiapoSelected) {
    //   return (this.Diapo = DiapoSelected);
    // },
    recupMusique(MusiqueSelected) {
      console.log("MusiqueSelected", MusiqueSelected);

      this.Musique =
        "https://www.symbiocenter.com/assets/musique_img/" +
        MusiqueSelected.attributes.name +
        ".jpg";
      this.AudioMusique =
        "https://www.symbiocenter.com/assets/musique/" +
        MusiqueSelected.attributes.cheminresource;
      console.log("AudioMusique", this.AudioMusique);
      console.log("Musique", this.Musique);
      this.toggleModal2();
    },
    recupIdDiapo(IdSelected) {
      return (
        (this.IdDiapo = IdSelected),
        this.callDataBackground(),
        this.toggleModal()
      );
    },
    toggleModal() {
      this.isOpen = !this.isOpen;
    },
    toggleModal2() {
      this.isOpen2 = !this.isOpen2;
    },
    validateDiapo() {
      this.isDiapo = false;
      this.isMusique = true;
      this.isOpen = false;
    },
    AnnulerDiapo() {
      this.isOpen = false;
    },
    validateMusique() {
      this.isMusique = false;
      this.isOpen2 = false;
      const audio = document.getElementById("audio_exo");
      audio.play();
      return (this.isFinish = true), this.callDataBackground();
    },
    AnnulerMusique() {
      this.isOpen2 = false;
    },
    resetDiapo() {
      this.isDiapo = true;
      this.isOpen = false;
      this.isMusique = false;
      this.isFinish = false;
    },
    callData() {
      ParseConfig();
      const ObjectSlideShow = Parse.Object.extend("audio_slideshow");
      const ObjectMusic = Parse.Object.extend("audio_music");
      const QuerySlideShow = new Parse.Query(ObjectSlideShow);
      const QueryMusic = new Parse.Query(ObjectMusic);
      try {
        QuerySlideShow.find().then((e) => {
          console.log("Element Slideshow", e);
          return (this.element_slideshow = e);
        });
        QueryMusic.find().then((e) => {
          return (this.element_music = e);
        });
        this.tableau_slideshow.push(this.element_slideshow);
        this.tableau_music.push(this.element_music);
        this.$forceUpdate();
      } catch {
        ("ERROR");
      }
    },
    async callDataBackground() {
      const ObjectSlideShow2 = Parse.Object.extend("audio_slideshow");
      const ObjectBackground = Parse.Object.extend("audio_background");
      const QueryBackground = new Parse.Query(ObjectBackground);
      const QuerySlideShow2 = new Parse.Query(ObjectSlideShow2);
      try {
        QuerySlideShow2.equalTo("objectId", this.IdDiapo);
        QueryBackground.matchesQuery("slideshow", QuerySlideShow2);
        const element_slideshow2 = await QuerySlideShow2.find();
        this.element_slideshow2 = element_slideshow2;
        this.NameOfDiapo = this.element_slideshow2[0].attributes.name;
        console.log("NameOfDiapo", this.NameOfDiapo);
        const element_background = await QueryBackground.limit(1000).find();
        this.element_background = element_background;
        console.log("Element Background", this.element_background);
        //compter il y a combien de diapo
        this.numberDiapo = this.element_background.length;
        console.log("Element Background", this.numberDiapo);
        // this.tableau_background.push(this.element_background);
        // this.tableau_slideshow2.push(this.element_slideshow2);
      } catch {
        ("ERROR");
      }
    },
  },
  watch: {
    screenWidth: function (newWidth) {
      if (newWidth < 400) {
        this.$el.classList.remove("class1");
        this.$el.classList.add("class2");
      } else {
        this.$el.classList.remove("class2");
        this.$el.classList.add("class1");
      }
    },
    isMuted(newVal) {
      if (newVal) {
        this.$refs.audio.volume = 0;
      } else {
        this.$refs.audio.volume = this.volume;
      }
    },
  },
};
</script>

<style>
.disable-mouse {
  pointer-events: none;
}

.dh-modal {
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  /* visibility: hidden; */
  width: 100%;
  height: 100%;
}

.buttonValidate {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonDiapo {
  cursor: pointer;
  margin: 0.5%;
  border: 0;
  background-color: transparent;
  transition: transform 0.1s;
}

.buttonDiapo:hover {
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
}

.dh-modal-heading {
  margin-bottom: 0;
  line-height: 1.5;
}

.PlacementBoutonJeu {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-left: 5px;
  border: #469281 1px solid;
  margin: 0 auto;
  width: 50em;
  background-color: #e5e5e5;
}

.dh-modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.dh-modal .dh-modal-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.dh-modal-wrapper {
  position: absolute;
  z-index: 9999;
  left: 50%;
  width: 32em;
  margin-left: -16em;
  background-color: #fff;
  box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
}

.dh-modal-transition {
  transition: all 0.3s 0.12s;
  transform: translateY(-10%);
  opacity: 0;
}

.buttonPerso {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-top: 0.4em;
}

@media (max-width: 768px) {
  .PlacementBoutonJeu {
    /*margin-left: 10em;*/
  }
}
@media (min-width: 768px) {
  .PlacementBoutonJeu {
    /*margin-left: 14em;*/
  }
}

.dh-modal .dh-modal-transition {
  transform: translateY(0);
  opacity: 1;
}

.dh-modal-header {
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid #e8e8e8;
  padding: 1em;
}

.dh-modal-body {
  padding: 1rem;
}

.dh-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  color: #aaa;
  background: none;
  border: 0;
  font-size: 18px;
}

.dh-modal-close:hover {
  color: #777;
}

.dh-modal-close:focus {
  outline: none;
}

.imgDiapo {
  transition: transform 0.1s;
  cursor: pointer;
}

.imgDiapo:hover {
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
}

.playAudio {
  position: absolute;
  top: 17%;
  left: 1%;
}

@media (max-width: 900px) {
  .playAudio {
    position: absolute;
    top: 17%;
    left: 2%;
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .playAudio {
    position: absolute;
    top: 17%;
    left: 2%;
  }
}

.titleAudio {
  position: absolute;
  top: 28%;
  left: 7%;
}

.volumeAudio {
  position: absolute;
  top: 25%;
  left: 82%;
}

.containerAudio {
  position: relative;
  min-width: 100%;
  min-height: 100%;
}

.AudioTimer {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.audio-player {
  --player-button-width: 3em;
  --sound-button-width: 2em;
  --space: 0.5em;
  border-radius: 30px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.audio-icon {
  width: 90%;
  height: 90%;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.player-button {
  background-color: transparent;
  border: 0;
  width: var(--player-button-width);
  height: var(--player-button-width);
  cursor: pointer;
  padding: 0;
}
.close-button {
  background-color: transparent;
  border: 0;
  width: var(--player-button-width);
  height: var(--player-button-width);
  cursor: pointer;
  padding: 0;
}
.sound-button {
  background-color: transparent;
  border: 0;
  width: var(--sound-button-width);
  height: var(--sound-button-width);
  cursor: pointer;
  padding: 0;
}

.timeline {
  -webkit-appearance: none;
  width: 100%;
  height: 65px;
  background-color: #e5e5e5;
  border-radius: 6px;
  border: 1px solid #00800069;
  background-size: 0% 100%;
  background-image: linear-gradient(#8bf0da, #6aafa0);
  background-repeat: no-repeat;
}

.timeline::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s;
  background-color: transparent#56ae9b;
}

.timeline::-moz-range-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s;
  background-color: transparent;
}

.timeline::-ms-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s;
  background-color: transparent;
}

.timeline::-webkit-slider-thumb:hover {
  background-color: transparent;
}

.timeline:hover::-webkit-slider-thumb {
  opacity: 0;
}

.timeline::-moz-range-thumb:hover {
  background-color: transparent;
}

.timeline:hover::-moz-range-thumb {
  opacity: 0;
}

.timeline::-ms-thumb:hover {
  background-color: transparent;
}

.timeline:hover::-ms-thumb {
  opacity: 0;
}

.timeline::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.timeline::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.timeline::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

svg {
  max-width: 100%;
}

.buttonPersonnalisation {
  text-decoration: none;
  text-transform: none;
  padding: 10px;
  min-height: 40px;
  border-radius: 20px;
  border: 0 none;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
  border: 1px solid #56ae9b;
}
@media (max-width: 950px) {
  .buttonPersonnalisation {
    background-color: transparent;
    padding: 0px;
    border: none;
  }
}
@media (min-width: 950px) {
  .buttonPersonnalisation {
    background-color: rgb(255, 255, 255);
    padding: 10px;
    border: 1px solid #56ae9b;
  }
}

.buttonPersonnalisatClose {
  text-decoration: none;
  text-transform: none;
  padding: 4px;
  min-height: 40px;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  color: #56ae9b;
  border: 0 none;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
  border: 1px solid #56ae9b;
}

.buttonPersonnalisations {
  z-index: 1;
  position: absolute;
  margin-top: 1%;
  text-decoration: none;
  text-transform: none;
  padding: 10px;
  min-height: 40px;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  color: #56ae9b;
  border: 0 none;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
  border: 1px solid #56ae9b;
  display: none;
}

.buttonPersonnalisationss {
  display: none;
  z-index: 1;
  position: absolute;
  margin-top: 1%;
  text-decoration: none;
  text-transform: none;
  padding: 10px;
  min-height: 40px;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  color: #56ae9b;
  border: 0 none;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
  border: 1px solid #56ae9b;
}

.Personnalisation {
  position: absolute;
  left: 62%;
  top: 15%;
}
@media (max-width: 950px) {
  .Personnalisation {
    left: 90%;
  }
}
.music-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5%; /* Vous pouvez ajuster cette valeur en fonction de vos besoins */
}
.music-item {
  flex: 0 0 auto;
  margin-bottom: 1.5%; /* Vous pouvez ajuster cette valeur en fonction de vos besoins */
}

.PlacementDiapo {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.PlayerButton {
  background-color: transparent;
  border: 0;
  width: var(--player-button-width);
  height: var(--player-button-width);
  cursor: pointer;
  padding: 0;
}

.imgFullScreen {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
  object-fit: cover;
}

input[name="VolumeSlider"] {
  -webkit-appearance: none;
  background-color: #e5e5e5;
  border: 1px solid #00800069;
  background-image: linear-gradient(#8bf0da, #6aafa0);
  height: 8px;
  border-radius: 5%;
  float: right;
  margin-top: 10px;
  margin-left: 0px;
  width: 68% !important;
}

input[name="VolumeSlider"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 2px solid #469281;
  height: 20px;
  width: 10px;
  border-radius: 20px;
  background: #56ae9b;
  cursor: pointer;
  margin-top: 0px;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

input[name="VolumeSlider2"] {
  -webkit-appearance: none;
  background-color: #e5e5e5;
  border: 1px solid #00800069;
  background-image: linear-gradient(#8bf0da, #6aafa0);
  height: 8px;
  border-radius: 5%;
  float: right;
  margin-left: 5px;
  cursor: pointer;
  width: 15%;
}

input[name="VolumeSlider2"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 2px solid #469281;
  height: 20px;
  width: 10px;
  border-radius: 20px;
  background: #56ae9b;
  cursor: pointer;
  margin-top: 0px;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

input[name="VolumeSlider3"] {
  -webkit-appearance: none;
  background-color: #e5e5e5;
  border: 1px solid #00800069;
  background-image: linear-gradient(#8bf0da, #6aafa0);
  height: 8px;
  border-radius: 5%;
  float: right;
  margin-left: 5px;
  cursor: pointer;
  width: 15%;
}

input[name="VolumeSlider3"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 2px solid #469281;
  height: 20px;
  width: 10px;
  border-radius: 20px;
  background: #56ae9b;
  cursor: pointer;
  margin-top: 0px;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

.playerFullScreen {
  background-color: transparent;
  border: 0;
  width: var(--player-button-width);
  height: var(--player-button-width);
  cursor: pointer;
  padding-right: 10px;
}

.bubbleTable {
  font-size: small !important;
  display: table;
}

.titleMobile {
  display: none;
}

.iconePerso {
  display: none;
}

@media (max-width: 950px) {
  .titleAudio {
    left: 20%;
  }

  .titleAudios {
    display: none !important;
  }

  .titleMobile {
    display: block;
    text-align: center;
  }

  .volumeAudio {
    display: none !important;
  }

  .iconePerso {
    display: block;
  }

  .textPerso {
    display: none;
  }

  .Perso {
    position: absolute;
    top: 15%;
  }

  /* .Personnalisation {
    display: none;
  } */

  .buttonPersonnalisationss {
    display: block !important;
    display: none;
  }

  .buttonPersonnalisatClose {
    display: none;
  }
}
.carousel-container {
  overflow: hidden;
  max-width: 1000px; /* ajustez cette valeur en fonction de la taille de votre diaporama */
  margin: auto; /* centre le diaporama horizontalement */
  position: relative;
  z-index: 1;
}

.close-carousel-btn {
  position: absolute;
  top: 10px; /* Ajustez selon votre besoin */
  right: 10px; /* Ajustez selon votre besoin */
  z-index: 10; /* Pour s'assurer que le bouton reste au-dessus du diaporama */
  background-color: rgba(
    0,
    0,
    0,
    0.7
  ); /* Transparence pour mieux voir le bouton */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.action-btn {
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.action-btn:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.fullscreen-image {
  max-width: 100vw !important;
  max-height: 100vh;
}
</style>
<style lang="scss">
.action-bar {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  display: flex;
  gap: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px 15px;
  border-radius: 25px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); /* Ombre douce pour une profondeur subtile */
}

.action-btn {
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px; /* Ajustez selon la taille des icônes */
  padding: 8px;
  border-radius: 50%; /* Boutons circulaires */
  transition: background-color 0.3s, transform 0.3s; /* Animation douce */

  /* Animation au survol */
  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
    transform: scale(1.1); /* Léger effet d'agrandissement au survol */
  }
}
.volume-control {
  position: relative;
  display: inline-block;
}

.volume-slider {
  position: absolute;
  bottom: 100%; /* position it above the button */
  left: 50%; /* center it horizontally */
  transform: translateX(-50%) rotate(-90deg); /* rotate and adjust position */
  display: none; /* hide by default */
  height: 100px; /* you can adjust this to your liking */
  cursor: pointer; /* indicate it's draggable */
  writing-mode: bt-lr; /* IE11 compatibility */
}

.volume-control:hover .volume-slider {
  display: block; /* show on hover */
}
</style>
