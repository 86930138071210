<template>
  <html
    id="chart-component"
    style="width: 100%; background: white; border-radius: 0px 0px 10px 10px"
  >
    <!--<img src='../assets/img/PasDeSignal.png' height='100px'>-->
    
    <div style="width: 100%" class="ResponsiveMoniteur">
      <div class="top-div" style="height: 350px" v-if="disableButton">
        <div class="center-button">
          <div id="timerAffichage">{{ timer }}</div>
          <button
            id="buttonsocket"
            class="buttonChart2"
            style="background-color: rgb(35, 147, 128); color: white"
            v-on:click="
              ReinitSocket(),
                (disableButton = false),
                launchRythmeRespiratoire(),
                startTimer(),
                timerExercice()
            "
          >
            Démarrer
          </button>
          <div class='buttonListFullScreen'>
        <button
    v-if="!isFullscreen && !FinishAniBool"
    class="buttonChart"
    style="background-color: rgb(35, 147, 128); color: white;"
    @click="chartFullScreen()"
  >
    Plein écran
  </button>
  <button
    v-else-if="!FinishAniBool"
    class="buttonChart2"
    style="background-color: rgb(35, 147, 128); color: white;"
    @click="exitFullscreen()"
  >
    Quitter le mode plein écran
  </button>
      </div>
          <div id="PlacementMoniteurPasLance">
              <div id="sensor-instructions-container">
                <div class="button-container">
                  <button
                    v-on:click="openAppInAppStore()"
                    class="buttonChart"
                    style="background-color: rgb(35, 147, 128); color: white"
                  >
                    Ouvrir le SymbioSensor
                  </button>
                </div>
                <p class="sensor-instructions-heading">
                  Si vous avez déjà installé le SymbioSensor, assurez-vous des
                  points suivants :
                </p>
                <ul class="sensor-instructions-list">
                  <li>
                    Le logiciel SymbioSensor est en cours d'exécution sur votre
                    ordinateur et utilise le même identifiant que votre session
                    en cours.
                  </li>
                  <li>Le capteur est correctement connecté à votre machine.</li>
                </ul>
              </div>
            </div>
        </div>
      </div>
      <div v-else class="top-div">
        <div
          v-if="MoniteurForRessources && isCardRedirectFront === false"
          class="PlacementBoutonTerminer"
        >
          <button
            id="buttonForFinishMoniteur"
            v-on:click="TerminerMoniteur()"
            class="buttonChart2"
            style="
              background-color: rgb(35, 147, 128);
              color: white;
              display: block !important;
            "
          >
            Terminer
          </button>
        </div>
        <div id="timer" class="timerInChart">{{ timer }}</div>
        <div class='buttonListFullScreen'>
        <button
    v-if="!isFullscreen && !FinishAniBool"
    class="buttonChart"
    style="background-color: rgb(35, 147, 128); color: white;"
    @click="chartFullScreen()"
  >
    Plein écran
  </button>
  <button
    v-else-if="!FinishAniBool"
    class="buttonChart2"
    style="background-color: rgb(35, 147, 128); color: white;"
    @click="exitFullscreen()"
  >
    Quitter le mode plein écran
  </button>
      </div>
        <!-- <div id="chartdiv"></div> -->
        <real-time-chart
      :value1="y_value"
      :value2="y_value"
      :lastBPM="y_value"
    />
        <div class="ReloadInChart" id="divCalibrageEnCours">
          <div class="containerMoniteurLoader">
            <div class="loader-containerMoniteurLoader">
              <div
                class="spinnerMoniteurLoader"
                :style="{ 'border-top-color': spinnerColor }"
              ></div>
              <p class="textMoniteurLoader">Calibration en cours</p>
            </div>
          </div>
        </div>
        <div class="ReloadInChart" v-if="LoadingRelecture">
          <div class="containerMoniteurLoader">
            <div class="loader-containerMoniteurLoader">
              <div
                class="spinnerMoniteurLoader"
                :style="{ 'border-top-color': spinnerColor }"
              ></div>
              <p class="textMoniteurLoader">Relecture en cours</p>
            </div>
          </div>
        </div>
      </div>
      <div class="PlacementAudio" v-if="game_type == false">
        <audioPlayer
          ref="audioPlayerRef"
          :titleAudio="titleAudioMoniteur"
          :sourceAudio="sourceAudioMoniteur"
          :timerAudio="timerAudioMoniteur"
          :audioMoniteur="false"
          :audioMoniteurPlay="audioMoniteurPlay"
        ></audioPlayer>
      </div>
      <div class="bottom-divs">
        <div class="bottom-div">
          <div id="cercleBPM">
            <img
              src="../assets/img/noDeviceFound.png"
              id="imageBPM"
              style="display: none"
            />
            <span id="bpm">BPM</span>
            <span id="BpmInstant"></span>
            <span id="RrInstant"></span>
            <img
              src="../assets/img/LogoPasDeSignal.png" key=""
              id="petite-image"
              style="display: none"
            />
          </div>
          <p class="textMoniteur">BATTEMENT CARDIAQUE INSTANTANÉE</p>
        </div>
        <div v-if="game_type" class="bottom-div">
          <div v-if="displayGuide">
            <!--Envoyer l'id user de ce qu'on reçoit-->
            <GuideRespiratoire
              :key="keyGuide"
              @inspiration-updated="updateInspirationParent"
              @expiration-updated="updateExpirationParent"
              @pauseInspiration-updated="updatePauseInspirationParent"
              @pauseExpiration-updated="updatePauseExpirationParent"
              :MoniteurInProgrammes="MoniteurInProgrammes"
              :idUserForSave="idUserForSave"
              :idPatientTherapeute="idPatientTherapeute"
              :inspirationParent="inspiration"
              :expirationParent="expiration"
              :pauseExpirationParent="pauseExpiration"
              :pauseInspirationParent="pauseInspiration"
              :gaugeEnabled="gaugeEnabled"
            />

             <label style="display: flex;margin-top:20px;">
              <input
                class="checkbox"
                style="margin-right: 3px"
                type="checkbox"
                v-model="gaugeEnabled"
              />
              <p v-if="gaugeEnabled">Activé</p>
              <p v-else>Désactivé</p>
            </label> 
          </div>
          <p class="textMoniteur">GUIDE RESPIRATOIRE</p>
        </div>
        <div class="bottom-div">
          <div>
            <div class="gauge">
              <div class="background-bar"></div>
              <div
                class="gauge-bar"
                :style="{ height: percent + '%', bottom: 0 }"
              >
                <div id="bubble-dialog" class="bubble-dialog">
                  <p>{{ percent + "%" }}</p>
                </div>
              </div>
              <div class="gauge-indicators">
                <div
                  class="gauge-indicator"
                  v-for="n in [100, 80, 60, 40, 20, 0]"
                  :key="n"
                  :style="{ top: 100 - n - 5 + '%' }"
                >
                  {{ n }}%
                </div>
              </div>
            </div>
          </div>
          <p class="textMoniteur">COHÉRENCE CARDIAQUE INSTANTANÉE</p>
        </div>
        <div class="bottom-div" v-if="disableButton === false">
          <div id="piechartdiv"></div>
          <div
            id="circleChartDiv"
            style="z-index: 0"
            class="containerCircleChart"
          >
            <div class="circleChart">Calibration en cours</div>
          </div>
          <p class="textMoniteur">MOYENNE COHÉRENCE CARDIAQUE</p>
        </div>
        <div v-else class="bottom-div">
          <div
            id="circleChartDiv"
            style="z-index: 0"
            class="containerCircleChart"
          >
            <div class="circleChart">Calibration en cours</div>
          </div>
          <!-- <div class="pie-chart">
            <div class="slice red" id="slice1"></div>
            <div class="slice yellow" id="slice2"></div>
            <div class="slice green" id="slice3"></div>
            <div class="center-value">50%</div>
          </div> -->
          <p class="textMoniteur">MOYENNE COHÉRENCE CARDIAQUE</p>
        </div>
      </div>
      <div style="margin: 10px 23px 0 23px">
        <button @click="toggleCommentaire" class="toggle-button">
            <span v-if="showCommentaire">&#9660; Masquer le commentaire</span>
            <!-- Flèche vers le bas -->
            <span v-else>&#9658; Ajouter un commentaire</span>
            <!-- Flèche vers la droite -->
          </button>
          <!-- Input conditionnellement affiché avec une transition -->
            <b-field label="Commentaire :" v-if="showCommentaire">
              <b-input
                @input="emitCommentaire"
                maxlength="200"
                type="textarea"
                v-model="commentaireMoniteur"
              ></b-input>
            </b-field>
      </div>
     
       <div v-if="isFullscreen" style="display:flex;">
        <img style="margin-left:auto;margin-right:auto;width:30rem" src="../../../assets/img/logo_symbiofi.png"></img>
      </div>

      <!--Modale-->
      <div v-if="openModalCPM">
        <div class="modal-background-cpm" @click="openModalCPM = false"></div>
        <div class="modal-content-cpm">
          <!-- Croix pour fermer la modale -->
          <button class="close-button-cpm" @click="openModalCPM = false">
            &times;
          </button>
          <div>{{ currentObject.MessagePalier }}</div>
        </div>
      </div>
      <div style="display: none" id="ShowDataInPage"></div>
      <div style="display: none" id="ShowCCInPage"></div>

      <!-- <button v-on:click="recupDataForRelecture()">
        recupDataForRelecture
      </button> 
      <a @click="isCardRedirectFront = true"> relecture </a> -->
    </div>
    
   <!--  <b-modal class='custom-modal-relecture'
      :width="1500"
      :active.sync="isCardRedirectFront"
      no-close-on-esc
      no-close-on-backdrop
      :can-cancel="false"
    >
      <div style="background-color: white; border-radius: 20px; padding: 20px">
        <AppelRelectureVue
          :valeurX="valeurX"
          :fcMoyenne="fcMoyenne"
          :ccMoyenne="ccMoyenne"
          :couleurCCMoyenne="couleurCCMoyenne"
          :TabRowFC="TabRowFC"
          :DefaultOpenedDetails="DefaultOpenedDetails"
          :ValeurCCX="ValeurCCX"
          :TabRowCC="TabRowCC"
          :labelChrono="timerMoniteurExpert"
          :valeurRouge="valeurRouge"
          :valeurVerte="valeurVerte"
          :valeurOrange="valeurOrange"
        >
        </AppelRelectureVue>
        <div class="PlacementBoutonActionMoniteur">
          <button
            v-on:click="SaveDataInParse(), reloadComponent()"
            id="buttonSave"
            class="buttonChart"
            style="background-color: rgb(35, 147, 128); color: white"
          >
            Terminer et Sauvegarder
          </button>
          <button
            v-on:click="(isCardRedirectFront = false), reloadComponent()"
            id="buttonAnnuler"
            class="buttonChart"
            style="background-color: gray !important; color: white"
          >
            Annuler
          </button>
        </div>
      </div>
    </b-modal>-->
    <transition name="slide-fade">
      <div class="notification-container2" v-if="showNotification">
        <div class="notification-box">
          <button @click="closeNotification" class="notification-close">
            &times;
          </button>
          <div class="notification-text">{{ notificationMessage }}</div>
        </div>
      </div>
    </transition>
    <popUpRelecture 
          :FinishAniBool="FinishAniBool"
          :valeurX="valeurX"
          :fcMoyenne="fcMoyenne"
          :ccMoyenne="ccMoyenne"
          :couleurCCMoyenne="couleurCCMoyenne"
          :valeurY="TabRowFC"
          :DefaultOpenedDetails="DefaultOpenedDetails"
          :valeurCCX="ValeurCCX"
          :valeurCCY="TabRowCC"
          :TimeCalibrage="60"
          :elapsedExerciseTime="labelChrono"
          :valeurRouge="valeurRouge"
          :valeurVerte="valeurVerte"
          :valeurOrange="valeurOrange"
          @save-data="handleSaveData"
          @commentaire-change="handleCommentaireChange"
    ></popUpRelecture> 
  </html>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";
import AppelRelectureVue from "@/components/AppelRelecture.vue";
const User = createNamespacedHelpers("user");
const io = require("socket.io-client");
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import { createNamespacedHelpers } from "vuex";
import audioPlayer from "./audioPlayer.vue";
import GuideRespiratoire from "@/components/moniteur/GuideRespiratoire.vue";
import RealTimeChart from "@/components/chart/RealTimeChartBiofeedback.vue";
import popUpRelecture from "@/components/chart/PopUpRelectureBiofeedback.vue";


export default {
  data() {
    return {
      spinnerColor: "rgb(72, 145, 129)",
      isCardRedirectFront: false,
      timer: "2:30",
      minutesForTimer: 2,
      secondsForTimer: 30,
      intervalForTimer: null,
      commentaireMoniteur: "",
      intervalTime: 0,
      disableButton: true,
      DisplayChartDiv: false,
      timePassed: 0,
      timerInterval: null,
      index: 0,
      startTime: null,
      currentObject: null,
      displayObject: false,
      progress: 0,
      percent: 100,
      audioMoniteurPlay: false,
      interval: null,
      direction: 1,
      downTime: 5500,
      upTime: 4500,
      pauseTopTime: 0,
      pauseBottomTime: 0,
      intervalTime: 0,
      pauseTimer: null,
      openModalCPM: false,
      OpenModalFinish: false,
      openModal: false, // État de la modale (ouverte ou fermée)
      inspiration: 4, // Valeur du champ de chiffres "Inspiration"
      pauseInspiration: 0, // Valeur du champ de chiffres "Pause inspiration"
      expiration: 6, // Valeur du champ de chiffres "Expiration"
      pauseExpiration: 0, // Valeur du champ de chiffres "Pause expiration"
      cpm: 6, // Valeur du champ de sélection "CPM"
      profile: "basique", // Valeur du champ de sélection "Profile"
      tableau_cpm_reglages: [5, 6, 7, 8, 9, 10, 11, 12], // Tableau des valeurs du champ de sélection "CPM"
      //Données pour la relectures
      valeurX: [],
      fcMoyenne: 73,
      ccMoyenne: 48,
      couleurCCMoyenne: "#FE0000",
      isLoading: true,
      TabRowFC: [],
      DefaultOpenedDetails: "5VBBk5J53k",
      ValeurCCX: [43, 67, 68, 82, 98],
      TabRowCC: [],
      labelChrono: "01:38",
      valeurRouge: 0,
      valeurVerte: 0,
      valeurOrange: 0,
      ObjectDataForRelecture: [],
      isFullscreen: false,
      alertDisplayed: false,
      showNotification: false,
      notificationMessage: "",
      durationInSeconds: 900,
      gaugeEnabled: true,
      tempsTotal: 0,
      elapsedTime: 0,
      connexionWebSocket: false,
      exerciseStartTime: 0,
      timerEnCours: true,
      idUserForSave: null,
      timerMoniteurExpert: "",
      UtilisateurCPM: "",
      UpdateKey: 0,
      keyGuide:0,
      socket: null,
      displayGuide: true,
      x_value: 0,
      y_value: 0,
      FinishAniBool: false,
      showCommentaire: false,
      LoadingRelecture: false,
      commentaire:"",
    };
  },
  watch: {
    inspiration: function () {
      
      //convertir en millisecondes
      var data = this.inspiration * 1000;
      this.upTime = data;
    },
    pauseInspiration: function () {
      
      //convertir en millisecondes
      var data = this.pauseInspiration * 1000;
      this.pauseTopTime = data;
    },
    expiration: function () {
      
      //convertir en millisecondes
      var data = this.expiration * 1000;
      this.downTime = data;
    },
    pauseExpiration: function () {
      
      //convertir en millisecondes
      var data = this.pauseExpiration * 1000;
      this.pauseBottomTime = data;
    },
  },
  created() {
    this.defaultData = { ...this.$data };
  },
  props: {
    Inspi: {
      required: true,
    },
    flux: {
      required: true,
    },
    avecGuide: {
      required: true,
    },
    Expi: {
      required: true,
    },
    avecCapteur: {
      required: true,
    },
    avecParametres: {
      required: true,
    },
    pcm: {
      required: true,
    },
    tableau: {
      required: true,
    },
    temps: {
      required: true,
    },
    game_type: {
      type: Boolean,
      default: true,
    },
    timerAudioMoniteur: {
      type: Number,
    },
    sourceAudioMoniteur: {
      type: String,
    },
    titleAudioMoniteur: {
      type: String,
    },
    fileReçu: {
      type: Object,
    },
    folderIdReçu: {},
    MoniteurForRessources: {
      type: Boolean,
      default: false,
    },
    idPatientTherapeute: {
      type: String,
    },
    MoniteurInProgrammes: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    audioPlayer,
    AppelRelectureVue,
    GuideRespiratoire,
    RealTimeChart,
    popUpRelecture,
  },
  mounted() {
    document.addEventListener('fullscreenchange', this.onFullscreenChange);
    if (localStorage.getItem("idUserForSave")) {
      this.idUserForSave = localStorage.getItem("idUserForSave");
    }
    if (
      this.fileReçu &&
      this.fileReçu.attributes.temps &&
      !this.MoniteurForRessources
    ) {
      this.minutesForTimer = this.fileReçu.attributes.temps;
      this.secondsForTimer = 0;
      this.timer = this.minutesForTimer + ":" + this.secondsForTimer;
    } else {
      this.minutesForTimer = 0;
      this.secondsForTimer = 0;
      this.timer = this.minutesForTimer + ":" + this.secondsForTimer;
    }
    document.getElementById("bubble-dialog").style.display = "none";
    this.updateInterval();
    this.callSocket();

    setInterval(() => {
      this.CCInstant();
    }, 1000);

    const slice1 = document.querySelector("#slice1");
    const slice2 = document.querySelector("#slice2");
    const slice3 = document.querySelector("#slice3");

    const redPercentage = 30;
    const yellowPercentage = 50;
    const greenPercentage = 20;

    const updateChart = () => {
      let startDegree = 0;
      const values = [redPercentage, yellowPercentage, greenPercentage];

      for (let i = 0; i < values.length; i++) {
        const slice = [slice1, slice2, slice3][i];
        const value = values[i];
        const endDegree = (value / 100) * 360 + startDegree;
        if (slice) {
          slice.style.transform = `rotate(${startDegree}deg)`;
          slice.style.clip = `rect(0, 150px, 300px, 150px)`;
          slice.style.transform = `rotate(${endDegree}deg)`;
          slice.style.clip = `rect(0, 150px, 300px, 0)`;
        }

        startDegree = endDegree;
      }
    };

    setInterval(updateChart, 1000);
  },
  beforeDestroy() 
  {
    console.log('beforeDestroy',this.socket);
    this.socket.disconnect();
    this.socket.close();
    this.connexionWebSocket = false;
    document.removeEventListener('fullscreenchange', this.onFullscreenChange);
    clearInterval(this.interval);
    delete window.vueComponent;    
  },
  methods: {
    updateInspirationParent(newValue) {
    //console.log('Une nouvelle valeur est arrivée')
    this.inspiration = newValue;
    this.keyGuide++;
  },
  handleCommentaireChange(commentaire) {
    this.commentaire = commentaire;
  },
  emitCommentaire() {
    this.commentaire = this.commentaireMoniteur;
  },
  SaveDataCPM()
  {
    var User = Parse.User.current();
    var UserParamGame = Parse.Object.extend("user_param_game");
    var queryUserParamGame = new Parse.Query(UserParamGame);
    var paramJeux =
    {
      Inspi: this.inspiration,
      Expi: this.expiration,
      InspiPause: this.pauseInspiration,
      ExpiPause: this.pauseExpiration,
    }
    var paramJeuxString = JSON.stringify(paramJeux);
    console.log('paramJeuxString', paramJeuxString);

  },
  updateExpirationParent(newValue) {
    //console.log('Une nouvelle valeur est arrivée')
    this.expiration = newValue;
    this.keyGuide++;
  },
  updatePauseInspirationParent(newValue) {
    //console.log('Une nouvelle valeur est arrivée')
    this.pauseInspiration = newValue;
    this.keyGuide++;
  },
  updatePauseExpirationParent(newValue) {
    //console.log('Une nouvelle valeur est arrivée')
    this.pauseExpiration = newValue;
    this.keyGuide++;
  },
  toggleCommentaire() {
      this.showCommentaire = !this.showCommentaire;
    },
    UpdateAnimation()
    {
      this.UpdateKey++;
    },
    onFullscreenChange() {
      this.isFullscreen = document.fullscreenElement !== null;
    },
    timerExercice() {
      this.exerciseStartTime = Date.now();

      setInterval(() => {
        if (this.timerEnCours) {
          this.elapsedTime = Math.floor(
            (Date.now() - this.exerciseStartTime) / 1000
          );
        }
      }, 1000);
    },
    chartFullScreen() {
      const myDiv = document.getElementById("chart-component");
      this.toggleFullscreen(myDiv);
      this.isFullscreen=true;
    },
    toggleFullscreen(element) {
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
     CCInstant() {
  var CCInstantRecup = document.getElementById("ShowCCInPage");
  if (CCInstantRecup) {
    try {
      // Vérifier si le contenu est non vide et un JSON valide
      if (CCInstantRecup.innerHTML.trim() !== "") {
        this.percent = JSON.parse(CCInstantRecup.innerHTML);
      } else {
        console.error("Le contenu de ShowCCInPage est vide.");
        this.percent = null;
      }
    } catch (error) {
      console.error("Erreur de parsing JSON:", error);
      this.percent = null;
    }
  } else {
    console.error("Élément ShowCCInPage non trouvé.");
    this.percent = null;
  }
},
    DisplayId() {
    },
    reloadComponent() {
      this.$emit("moniteur-finish", true);
      this.ReinitSocket(),
        (this.disableButton = true),
        (this.minutesForTimer = 0),
        (this.secondsForTimer = 0);
      this.timer = this.minutesForTimer + ":" + this.secondsForTimer;
    },
    TerminerMoniteur() {
      this.LoadingRelecture = true;
      this.timerEnCours = false;
      setTimeout(() => {
        this.recupDataForRelecture();
      }, 1000);
      setTimeout(() => {
        this.isCardRedirectFront = true;
        this.LoadingRelecture = false;
      }, 1000);
    },
    onKeydown(event) {
    if (event.key === 'Escape') {
      this.isFullscreen = false;
    }
  },
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen(); // Pour les navigateurs WebKit (Safari, Chrome)
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen(); // Pour les navigateurs Mozilla (Firefox)
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen(); // Pour les navigateurs Microsoft (IE, Edge)
      }
      this.isFullscreen = false;
    },
    async SaveDataInParse() {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      var resultat = JSON.stringify(this.ObjectDataForRelecture);
      // console.log("resultat", resultat)
      // console.log("this.folderIdReçu", this.folderIdReçu)
      // console.log("this.fileReçu", this.fileReçu)
      // console.log("this.idPatientTherapeute", this.idPatientTherapeute)
      // console.log("this.idUserForSave", this.idUserForSave)
      // console.log("this.elapsedTime", this.elapsedTime)
      // console.log("this.MoniteurInProgrammes", this.MoniteurInProgrammes)
      console.log("this.commentaireMoniteur", this.commentaireMoniteur)
      console.log("this.commentaire", this.commentaire)

      try {
        // this.folderIdReçu = JSON.stringify(this.folderIdReçu);
        var fileReçuId = this.fileReçu.id;
        if(this.MoniteurForRessources)
        {
          console.log('MoniteurForRessources')
          var folderIdReçu = this.folderIdReçu.id;
          var MoniteurInProgrammes = this.MoniteurForRessources;
        }
        else
        {
          console.log('MoniteurNotForRessources')
          var folderIdReçu = this.folderIdReçu;
          var MoniteurInProgrammes = this.MoniteurInProgrammes;
        }
        console.log('folderIdReçu', folderIdReçu)
        console.log('MoniteurInProgrammes', MoniteurInProgrammes)
        Parse.Cloud.run(
          "saveExo",
          {
            resultat:resultat,
            idPatidPatientTherapeute: this.idPatientTherapeute,
            idUserForSave: this.idUserForSave ? this.idUserForSave : this.idPatientTherapeute,
            elapsedTime: this.elapsedTime,
            note: 0,
            typeExercice:'MoniteurExpert',
            folderIdReçu: folderIdReçu,
            fileReçuId: fileReçuId,
            MoniteurInProgrammes: MoniteurInProgrammes,
            comment: this.commentaire,
          },
          {
            success: function (result) {
              
            // this.notificationMessage = "Exercice terminé";

              console.log("Sauvegarde effectué !");
            },
            error: function (error) {
              console.error(
                "Une erreur s'est produite lors de la sauvegarde :",
                error
              );
            },
          }
        );
        Toast.fire({
          icon: "success",
          title: "Votre exercice a bien été sauvegardé !",
        });
        setTimeout(() => {
          this.$emit("moniteur-finish", true);
        }, "1000");
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: "Une erreur s'est produite lors de la sauvegarde",
          text: error,
        });
        console.error(
          "Une erreur s'est produite lors de la sauvegarde :",
          error
        );
      }
    },
    handleSaveData()
    {
      this.SaveDataInParse();
      this.reloadComponent();
      
    },
    recupDataForRelecture() {
      var recupDataForRelecture =
        document.getElementById("ShowDataInPage").innerHTML;
      this.ObjectDataForRelecture = JSON.parse(recupDataForRelecture);
      this.valeurRouge = this.ObjectDataForRelecture.Pct1;
      this.valeurVerte = this.ObjectDataForRelecture.Pct3;
      this.valeurOrange = this.ObjectDataForRelecture.Pct2;
      if (this.fileReçu.attributes.temps) {
        this.labelChrono = this.fileReçu.attributes.temps;
      } else {
        this.labelChrono = this.elapsedTime;
      }
      
      //mettre cela en secondes
      // this.labelChrono = this.labelChrono * 60;
      this.valeurX = this.ObjectDataForRelecture.ArrayTacho.map(
        (objet) => objet.x
      );
      this.valeurX = this.valeurX.map((x) => x / 1000);
      //arondir à l'unité
      this.valeurX = this.valeurX.map((x) => Math.round(x));
      this.fcMoyenne = this.ObjectDataForRelecture.BpmCC;
      this.ccMoyenne = Math.round(this.ObjectDataForRelecture.AverageCC);
      this.TabRowFC = this.ObjectDataForRelecture.ArrayTacho.map(
        (objet) => objet.y
      );
      this.ValeurCCX = this.ObjectDataForRelecture.TabCC.map(
        (objet) => objet.x
      );
      this.ValeurCCX = this.ValeurCCX.map((x) => x / 1000);
      //arondir à l'unité
      this.ValeurCCX = this.ValeurCCX.map((x) => Math.round(x));
      this.TabRowCC = this.ObjectDataForRelecture.TabCC.map((objet) => objet.y);
      if(this.ccMoyenne)
      {
        if(this.ccMoyenne<60)
        {
          this.couleurCCMoyenne = "#FE0000";
        }
        else if(this.ccMoyenne<90)
        {
          this.couleurCCMoyenne = "#FFC801";
        }
        else if(this.ccMoyenne>80)
        {
          this.couleurCCMoyenne = "#51C901";
        }
      {
        
      }
      }
      this.FinishAniBool = true;

      return this.ObjectDataForRelecture;
      
    },
    startTimerForNotif() {
      this.showNotification = true;
      this.progressWidth = 0;
      if (this.intervalId) clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        this.progressWidth += 3.33;
        if (this.progressWidth >= 100) {
          clearInterval(this.intervalId);
        }
      }, 100);
      setTimeout(() => {
        this.closeNotification();
      }, 3000);
    },
    closeNotification() {
      this.showNotification = false;
      this.progressWidth = 0;
    },
    startTimer() {
      this.exerciseStartTime = Date.now();
      setInterval(() => {
        if (this.timerEnCours) {
          this.elapsedTime = Math.floor(
            (Date.now() - this.exerciseStartTime) / 1000
          );
        }
        //transformer en 1:30
        const minutes = Math.floor(this.elapsedTime / 60);
        const seconds = this.elapsedTime % 60;
        this.timerMoniteurExpert = `${minutes}:${
          seconds < 10 ? "0" + seconds : seconds
        }`;
      }, 1000);
      if (this.MoniteurForRessources) {
        let timer = 0;
        let TimerFinish = false;
        setInterval(() => {
          if (this.timerEnCours) {
            if (timer >= 900) {
              // 15 minutes = 900 secondes
              if (TimerFinish === true) {
                return;
              } else {
                this.isCardRedirectFront = true;
                TimerFinish = true;
                this.recupDataForRelecture();
                return;
              }
            }

            const minutes = Math.floor(timer / 60);
            const seconds = timer % 60;

            this.timer = `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
            timer++;
          }
        }, 1000);
      } else {
        let TimerFinish = false;
        setInterval(() => {
          if (this.timerEnCours) {
            if (this.secondsForTimer === 0) {
              if (this.minutesForTimer === 0) {
                if (TimerFinish === true) {
                  return;
                } else {
                  this.isCardRedirectFront = true;
                  TimerFinish = true;
                  this.recupDataForRelecture();
                  return;
                }
              }
              this.minutesForTimer--;
              this.secondsForTimer = 59;
            } else {
              this.secondsForTimer--;
            }
            this.timer = `${this.minutesForTimer}:${
              this.secondsForTimer < 10
                ? "0" + this.secondsForTimer
                : this.secondsForTimer
            }`;
          }
        }, 1000);
      }
    },

    lancerAudio() {
      this.$refs.audioPlayerRef.lancerAudio();
    },
    launchRythmeRespiratoire() {
      this.startTime = Date.now();
      setInterval(() => {
        let now = Date.now();
        let diff = (now - this.startTime) / 1000;
        if (
          this.tableau.length > 0 &&
          diff >= this.tableau[this.index].Time &&
          this.index < this.tableau.length
        ) {
          this.currentObject = this.tableau[this.index];
          this.displayObject = true;
          this.index++;
          this.inspiration = this.currentObject.Inspi;
          this.expiration = this.currentObject.Expi;
          this.pauseExpiration = this.currentObject.InspiPause;
          this.pauseInspiration = this.currentObject.ExpiPause;
          
          
          this.openModalCPM = true;
          setTimeout(() => {
            this.openModalCPM = false;
          }, 10000);
        } else if (
          this.tableau.length > 0 &&
          diff >= this.tableau[this.tableau.length - 1].Time &&
          this.index === this.tableau.length
        ) {
          this.displayObject = false;
        }
      }, 1000);
    },
    updateInterval() {
      clearInterval(this.interval);
      clearTimeout(this.pauseTimer);
      this.intervalTime =
        this.direction === 1 ? this.downTime / 100 : this.upTime / 100;
      this.interval = setInterval(() => {
        this.progress += this.direction;
        if (this.progress === 0 || this.progress === 100) {
          clearInterval(this.interval);
          this.direction *= -1;
          this.pauseTimer = setTimeout(
            () => {
              this.updateInterval();
            },
            this.direction === 1 ? this.pauseBottomTime : this.pauseTopTime
          );
        }
      }, this.intervalTime);
    },
    changeVariables() {
      this.downTime = 1000;
      this.upTime = 1000;
      this.pauseTopTime = 1000;
      this.pauseBottomTime = 1000;
      this.openModalCPM = true;
      setTimeout(() => {
        this.openModalCPM = false;
      }, 10000);
    },
    closeModal() {
      this.showModal = false;
    },
    async openAppInAppStore() {
  let sessionToken = null;

   try {
     // Récupère ou crée la session utilisateur
     const response = await Parse.Cloud.run("getUserSessionOrCreate");
    console.log("Session récupérée ou créée avec succès:", response);
    sessionToken = response.sessionToken;
    console.log("Token de session:", sessionToken);
   } catch (error) {
     console.error("Erreur lors de la récupération ou création de la session:", error.message);
   }

  // Vous pouvez stocker ce token pour l'utiliser dans des requêtes futures
  try {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/Macintosh/.test(userAgent)) {
      // Ouvre l'application SymbioSensor dans le Mac App Store si l'application VueJS est exécutée sur un Mac
      window.open("https://apps.apple.com/fr/app/symbio/id1611896454", "_blank");
    } else if (/Windows/.test(userAgent)) {
      // Ouvre l'application SymbioSensor directement via le schéma URL avec le token de session si présent
      if (sessionToken) {
        const appUrl = `symbiosensor://?token=${encodeURIComponent(sessionToken)}`;
        window.open(appUrl, "_blank");
      } else {
        // Si le token n'est pas présent, ouvre l'application SymbioSensor dans le Microsoft Store
        const appUrl = "https://www.microsoft.com/store/productId/9NQ9QQ9T3R2W?ocid=pdpshare";
        window.open(appUrl, "_blank");
      }
    } else if (/Android/.test(userAgent)) {
      // Ouvre l'application SymbioSensor dans le Google Play Store si l'application VueJS est exécutée sur un appareil Android
      window.open("https://play.google.com/store/apps/details?id=com.symbiosensor", "_blank");
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // Ouvre l'application SymbioSensor dans l'App Store pour les appareils iOS
      window.open("https://apps.apple.com/fr/app/symbio/id1611896454", "_blank");
    } else {
      // Gérer les autres cas ou les plateformes non spécifiées
      console.warn('Plateforme non prise en charge. Veuillez accéder à la boutique d’applications appropriée.');
      // Optionnel : Redirection vers une page de support ou d'information
    }
  } catch (error) {
    console.error('Erreur lors de l’ouverture de l’application dans le store:', error);
    // Optionnel : Afficher un message d’erreur à l'utilisateur
  }
},




    ReinitSocket() {
      if (this.game_type === false) {
        this.lancerAudio();
      }
      document.getElementById("bubble-dialog").style.display = "block";
      this.disableButton = false;
      function onOpen() {
        var message =
          '{"userId":"' +
          Parse.User.current().attributes.username +
          '","msg":"reinitialisation"}';
        websocket.send(message);
      }
      var wsUri = "wss://www.symbiocenter.com/reinitialisation";
      var websocket = new WebSocket(wsUri);
      websocket.onopen = function () {
        onOpen();
      };
      this.callSocket();
      this.piechartLaunch();
    },
    piechartLaunch() {
      setTimeout(() => {
        document.getElementById("piechartdiv").style.display = "block";
        document.getElementById("circleChartDiv").style.display = "none";
      }, 80000);
    },
    callSocket() {
      if (document.getElementById("buttonsocket")) {
        document.getElementById("buttonsocket").style.display = "none";
      }
      ParseConfig();

      //Connection vers la websocket
      this.socket = io.connect("https://www.symbiocenter.com", {});
      this.socket.on("connect", () => {
        this.connexionWebSocket = true;
        //authentification de la websocket
        this.socket.emit("authentication", {
          username: Parse.User.current().attributes.username,
        });

        this.socket.on("authenticated", function () {
        });
        this.socket.on("unauthorized", function () {
        });
        this.socket.on("disconnect", function () {
        });

        //Déclaration des variables utiles lors de la réception des données
        var tableauData = [];
        var tableauX = [];
        var tableauY = [];
        var BpmInstant = 0;
        var BpmT = "BPM";
        var rrInstatane = 0;
        var rrInstataneT = "RR: ";
        if (document.getElementById("chartdiv")) {
          var root = am5.Root.new("chartdiv");
        } else {
          var root = null;
        }

        if (document.getElementById("piechartdiv")) {
          var pieroot = am5.Root.new("piechartdiv");
        } else {
          var pieroot = null;
        }
        if (document.getElementById("piechartdiv")) {
          document.getElementById("piechartdiv").style.display = "none";
        }

        var Pct1Json = 0;
        var Pct2Json = 0;
        var Pct3Json = 0;
        var MoyCCJson = 0;
        var lastBPM = 0;
        var TabCC = [];
        var lastTimestamp = 0;
        var CC = 0;
        this.socket.on("rr", (rr) => {
          if (document.getElementById("buttonsocket")) {
            document.getElementById("buttonsocket").style.display = "block";
          }
          //Entrée dans la fonction qui reçoit le RR
          var rrJson = JSON.parse(rr);
          console.log("rrJson", rrJson); 
          //Transforme le RR en JSON
          CC = rrJson.CC;
          if (Number.isFinite(CC)) {
            CC = CC.toFixed(0);
          } else {
         // Gérer le cas où CC n'est pas un nombre valide
           console.error('La valeur de CC n\'est pas un nombre valide:', CC);
          }
          NewDataCC(CC);
          Pct1Json = rrJson.PctT1;
          Pct2Json = rrJson.PctT2;
          Pct3Json = rrJson.PctT3;
          //Récupérer les pourcentages du Json

          MoyCCJson = rrJson.MoyCC;
          //Récupérer la moyenne du CC du Json

          lastBPM = Math.round(60000 / rrJson.rr);
          console.log('DERNIER BPM:', lastBPM);
          //Calcul du BPM
          if (Pct1Json + Pct2Json + Pct3Json != 0) {
            //Si les pourcentages ne sont pas nulle
            if (pieroot) {
              NewData(Pct1Json, Pct2Json, Pct3Json, MoyCCJson);
            }
            //Appel de la fonction NewData
          } else {
          }

          var rrFourni = rrJson.rr;
          var currentTimestamp = Date.now();
             if (lastTimestamp !== 0) {
                 var elapsedTime = currentTimestamp - lastTimestamp; // en millisecondes

             }
             lastTimestamp = currentTimestamp;
         
          //afficher le temps entre chaque valeur rr

          //Récupérer seulement le RR du Json
          var data = rrJson.traceTacho;
          //Récupérer seulement le traceTacho du Json

          if (rrJson != null) {
            //Si le signal n'est pas nulle
            for (var i = 0; i < data.length; i++) {
              //Pour chaque valeur du tableau data

              var x = data[i].x;
              //Récupérer la valeur x / 1000
              var y = data[i].y;
              if (y >40 && y < 150) {
                
              this.y_value = y;
              }
              const divCalibrageEnCours = document.getElementById(
            "divCalibrageEnCours"
          );
          if(divCalibrageEnCours)
          {
          divCalibrageEnCours.style.display = "none";
        }
              //Récupérer la valeur y
              if (x != null && y != null) {
                //Si x et y ne sont pas nulle
                if (y > 40 && y < 150) {
                  TabCC.push({ x: x, y: rrJson.CC });

                  //Si y est compris entre 40 et 150
                  tableauX.push(x);
                  //Ajouter x dans le tableauX
                  tableauY.push(y);
                  //Ajouter y dans le tableauY
                  tableauData.push({ x: x, y: y });
                  //Ajouter x et y dans le tableauData
                  if (root) {
                    recupY(y,x);
                  }
                  //Appel de la fonction recupY
                }
              }
            }
          }

          if (rrFourni > 0) {
            var fc1 = Math.round(60000 / rrFourni);
            BpmInstant = fc1;
            rrInstatane = rrFourni;
            if (BpmInstant <= 30 || BpmInstant >= 200) {
              if (document.getElementById("buttonsocket")) {
                document.getElementById("buttonsocket").disabled = true;
                document.getElementById("buttonsocket").style.display = "none";
                document.getElementById(
                  "PlacementMoniteurPasLance"
                ).style.display = "block";
              }
              if(document.getElementById("imageBPM") && document.getElementById("petite-image") && document.getElementById("BpmInstant") && document.getElementById("RrInstant") && document.getElementById("cercleBPM") && document.getElementById("bpm"))
              {
              document.getElementById("imageBPM").style.display = "block";
              document.getElementById("petite-image").style.display = "block";
              document.getElementById("BpmInstant").style.display = "none";
              document.getElementById("RrInstant").style.display = "none";
              document.getElementById("cercleBPM").style.borderColor = "red";
              document.getElementById("cercleBPM").style.background =
                "rgba(0, 0, 0, 0)";
              document.getElementById("bpm").style.display = "none";
              }
            } else {
              if (document.getElementById("buttonsocket")) {
                document.getElementById("buttonsocket").disabled = false;
                document.getElementById("buttonsocket").style.display = "block";
                document.getElementById(
                  "PlacementMoniteurPasLance"
                ).style.display = "none";
              }
              if(document.getElementById("imageBPM") && document.getElementById("petite-image") && document.getElementById("BpmInstant") && document.getElementById("RrInstant") && document.getElementById("cercleBPM") && document.getElementById("bpm"))
              {
              document.getElementById("imageBPM").style.display = "none";
              document.getElementById("petite-image").style.display = "none";
              document.getElementById("BpmInstant").style.display = "block";
              document.getElementById("RrInstant").style.display = "block";
              document.getElementById("bpm").style.display = "block";
              document.getElementById("cercleBPM").style.borderColor = "white";
              document.getElementById("cercleBPM").style.background =
                "rgb(72, 145, 129)";
              document.getElementById("BpmInstant").innerHTML = BpmInstant;
              document.getElementById("RrInstant").innerHTML =
                "RR: " + rrInstatane;
              }
            }
          }
        });

        // function recupQuality(quality)
        // {
        //   if (quality === 0) {
        //     document.getElementById("buttonsocket").disabled = true;
        //     document.getElementById("buttonsocket").style.backgroundColor =
        //       "#ff0000";
        //   }
        //   else {
        //     document.getElementById("buttonsocket").disabled = false;
        //   }
        // }

        //Fonction pour récupérer y pour le tacho
        function recupY(y,x) {
          var y_reçu = y;
          var x_reçu = x;
          if (root != null) {
            addData(y_reçu, x_reçu);
          }
        }
        //créer un moment de début
        var startTime = new Date();
        var started = false;
        function addData(y_reçu, x_reçu) {
          const divCalibrageEnCours = document.getElementById(
            "divCalibrageEnCours"
          );
          divCalibrageEnCours.style.display = "none";
          var y = y_reçu;
          var lastDataItem = series.dataItems[series.dataItems.length - 1];
           console.log("y_reçu", y_reçu,lastDataItem.get("valueY"));
         var lastValue = lastDataItem.get("valueY");
          var newValue = y_reçu;
          // var lastDate = new Date(lastDataItem.get("valueX"));
          // var time = am5.time.add(new Date(lastDate), "second", 1).getTime();
          series.data.removeIndex(0);
          var currentTime = new Date(); // Obtenir le temps actuel
          var timeElapsedInSeconds = (currentTime - startTime) / 1000; // Calculer le temps écoulé en secondes
          // console.log("time", time);
          // console.log("newValue", newValue);
          // console.log(this.connexionWebSocket);
          var newValueX = x_reçu;
          // var X_In_Seconds = newValueX / 1000;
          if(!started)
          {
            if(x_reçu===0)
            {
              started=true;
            }
            else
            {
              return;
            }
          }
          console.log('Y:',newValue ,'||',' X:',newValueX);
          series.data.push({
             date: newValueX,
            // date:this.elapsedTime,
            value: newValue,
          });

          var newDataItem = series.dataItems[series.dataItems.length - 1];
          //modifi de la valeur en point par seconde//
          newDataItem.animate({
            key: "valueYWorking",
            to: newValue,
            from: lastValue,
            duration: 600,
            easing: easing,
          });

          newDataItem.bullets = [];
          newDataItem.bullets[0] = lastDataItem.bullets[0];
          newDataItem.bullets[0].get("sprite").dataItem = newDataItem;
          lastDataItem.dataContext.bullet = false;
          lastDataItem.bullets = [];

          var animation = newDataItem.animate({
            key: "locationX",
            to: 0.5,
            from: -0.5,
            duration: 600,
          });
          if (animation) {
            var tooltip = xAxis.get("tooltip");
            if (tooltip && !tooltip.isHidden()) {
              animation.events.on("stopped", function () {
                xAxis.updateTooltip();
              });
            }
          }
        }
        function NewData(Pct1Json, Pct2Json, Pct3Json, MoyCCJson) {
          //Récupère la moyenne du CC et la transforme en entier
          MoyCCJson = MoyCCJson.toFixed(0);
          //Change le texte du label
          pielabel.set("text", MoyCCJson + "%");
          //Change les données du graphique
          pieseries.data.setAll([
            { sector: "Rouge", size: Pct1Json, color: am5.color("#fe0000") },
            { sector: "Jaune", size: Pct2Json, color: am5.color("#ffc801") },
            { sector: "Vert", size: Pct3Json, color: am5.color("#51c901") },
          ]);
        }
        // Code pour le graphique piechart (camembert)
        if (pieroot) {
          //Déclaration des variables
          var piechartData = {
            1995: [
              { sector: "Rouge", size: 1, color: am5.color("#ED1C24") },
              { sector: "Jaune", size: 0, color: am5.color("#FFF100") },
              { sector: "Vert", size: 99, color: am5.color("#0CFF00") },
            ],
          };

          //Ajout du thême
          pieroot.setThemes([am5themes_Animated.new(pieroot)]);

          //Déclaration du graphique
          var piechart = pieroot.container.children.push(
            am5percent.PieChart.new(pieroot, {
              radius: 80,
              innerRadius: 60,
              layout: pieroot.verticalLayout,
              shadowColor: am5.color(0, 0, 0, 0.9),
              shadowBlur: 15,
              shadowOffsetX: 15,
              shadowOffsetY: 15,
            })
          );

          //Déclaration des labels
          var pieseries = piechart.series.push(
            am5percent.PieSeries.new(pieroot, {
              valueField: "size",
              categoryField: "sector",
            })
          );

          //Label visible ou non
          pieseries.labels.template.set("visible", false);
          pieseries.ticks.template.set("visible", false);

          //Déclaration des couleurs
          pieseries
            .get("colors")
            .set("colors", [
              am5.color("#fe0000"),
              am5.color("#ffc801"),
              am5.color("#51c901"),
            ]);

          //Fonction pour changer les données du graphique

          //Données de base du graphique
          pieseries.data.setAll([
            { sector: "Rouge", size: 51, color: am5.color("#ED1C24") },
            { sector: "Jaune", size: 30, color: am5.color("#FFF100") },
            { sector: "Vert", size: 20, color: am5.color("#0CFF00") },
          ]);

          // Ajouter label
          var pielabel = pieroot.tooltipContainer.children.push(
            am5.Label.new(pieroot, {
              x: am5.p50,
              y: am5.p50,
              centerX: am5.p50,
              centerY: am5.p50,
              fill: am5.color(0x000000),
              fontSize: 30,
            })
          );

          var elapsedTime = 0;
          //Joue l'animation du graphique
          pieseries.appear(1000, 100);
          // Fonction permettant de sauvegarder les données
          var exerciseStartTime = Date.now();

          setInterval(() => {
            elapsedTime = Math.floor((Date.now() - exerciseStartTime) / 1000);
          }, 1000);

          setInterval(() => {
            if (document.getElementById("timer")) {
              let currentTime = document.getElementById("timer").innerHTML;
              if (currentTime === "0:00" && !this.alertDisplayed) {
                this.alertDisplayed = true;
                sendData();
              }
            }
          }, 1000);
          if (document.getElementById("buttonForFinishMoniteur")) {
            const divData = document.getElementById("buttonForFinishMoniteur");
            divData.addEventListener("click", sendData);
          }
          function sendData() {
            //Création de l'objet envoyé dans résultatjeux dans parse
            var resultat = {
              ArrayTacho: tableauData,
              TabCC: TabCC,
              AverageCC: MoyCCJson,
              TimeCompletion: elapsedTime,
              Timer: elapsedTime,
              BpmCC: lastBPM,
              Pct1: Pct1Json,
              Pct2: Pct2Json,
              Pct3: Pct3Json,
              Cpm: 6,
              Profil: "1",
              Inspi: 5,
              PauseInspi: 0,
              Expi: 5,
              PauseExpi: 0,
            };
            resultat = JSON.stringify(resultat);

            const DataDisplayInPage = document.getElementById("ShowDataInPage");
            DataDisplayInPage.innerHTML = resultat;
            // //Création de l'objet envoyé dans parse
          }
        }
        //Création de la jauge
        function NewDataCC(CC) {
          var CCInstantRecup = document.getElementById("ShowCCInPage");
          if (CCInstantRecup)
        {
          CCInstantRecup.innerHTML = CC;
        }
        }
        // Création du graphique tacho
        if (root) {
          //Ajout du thème
          root.setThemes([am5themes_Animated.new(root)]);

          var value = 0;
          //Fonction pour générer les premières données du graphique
          function generateChartData() {
            var chartData = [];
            var firstDate = new Date();
            firstDate.setDate(firstDate.getDate() - 1000);
            firstDate.setHours(0, 0, 0, 0);

            for (var i = 0; i < 100; i++) {
             var newDate = new Date(firstDate);
             newDate.setSeconds(newDate.getSeconds());

            //   // value += (Math.random() < 0.5 ? 1 : -1) * Math.random() * 10;
            //   console.log('newDateGetTime', newDate.getTime());
            //   console.log('value', value);
              chartData.push({
                date: newDate,
                value: value,
              });
             }
            return chartData;
          }

          var data = generateChartData();

          // Create chart
          // https://www.amcharts.com/docs/v5/charts/xy-chart/
          var chart = root.container.children.push(
            am5xy.XYChart.new(root, {
              focusable: true,
              // panX: true,
              // panY: true,
              // wheelX: "panX",
              // wheelY: "zoomX",
              //pinchZoomX: true,
            })
          );

          var easing = am5.ease.linear;

          // Create axes
          // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
          var xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
              maxDeviation: 0,
              groupData: false,
              extraMax: 0.1, // la distance de la valeurs actualisé par rapport à la droite du graph
              extraMin: -0.05, // this removes some space form th beginning so that the line would not be cut off
              //  baseInterval: {
              //    timeUnit: "millisecond",
              //    count: 25,
              //  },
              renderer: am5xy.AxisRendererX.new(root, {
                minGridDistance: 50,
                startLocation: 0,
              }),
              tooltip: am5.Tooltip.new(root, {}),

            })
          );

          var yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
              extraMax: 0.1, // la distance de la valeurs actualisé par rapport à la droite du graph
              //extraMin: -0.01, // this removes some space form th beginning so that the line would not be cut off
              renderer: am5xy.AxisRendererY.new(root, {}),
              // min: 40,
              //max: 150,
              //strictMinMaxSelection: true,
            })
          );

          chart.set("scrollbarX", am5.Scrollbar.new(root, { orientation: "horizontal" }));

          xAxis.set("baseInterval", { timeUnit: "millisecond", count: 100 });
          xAxis.set("dateFormats", {
  millisecond: "ss.SSS",
  second: "mm:ss",
  minute: "HH:mm:ss",
  hour: "HH:mm",
  day: "MMM dd",
  week: "MMM dd",
  month: "yyyy MMM",
  year: "yyyy"
});
          // Add series
          // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
          var series = chart.series.push(
            am5xy.LineSeries.new(root, {
              name: "Series 1",
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: "value",
              valueXField: "date",
              tooltip: am5.Tooltip.new(root, {
                pointerOrientation: "horizontal",
                labelText: "{valueY}",
              }),
            })
          );
          data[data.length - 1].bullet = true;
          series.data.setAll(data);

          series.bullets.push(function (root, series, dataItem) {
            if (dataItem.dataContext.bullet) {
              var container = am5.Container.new(root, {});
              var circle0 = container.children.push(
                am5.Circle.new(root, {
                  radius: 5,
                  fill: am5.color(0xff0000),
                })
              );
              var circle1 = container.children.push(
                am5.Circle.new(root, {
                  radius: 5,
                  fill: am5.color(0xff0000),
                })
              );

              circle1.animate({
                key: "radius",
                to: 20,
                duration: 1000,
                easing: am5.ease.out(am5.ease.cubic),
                loops: Infinity,
              });
              circle1.animate({
                key: "opacity",
                to: 0,
                from: 1,
                duration: 1000,
                easing: am5.ease.out(am5.ease.cubic),
                loops: Infinity,
              });

              return am5.Bullet.new(root, {
                locationX: undefined,
                sprite: container,
              });
            }
          });

          var cursor = chart.set(
            "cursor",
            am5xy.XYCursor.new(root, {
              xAxis: xAxis,
            })
          );
          cursor.lineY.set("visible", false);

          chart.appear(1000, 100);
        }
      });
    },
  },
};
</script>
<style scoped>
#chartdiv {
  width: 100%;
  height: 400px;
  max-width: 100%;
}

.circle {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  padding: 10px;
  background: #64ff64;
  border: 5px solid rgb(35, 147, 128);
  color: #000;
  text-align: center;
  font: 32px Arial, sans-serif;
}

#piechartdiv {
  margin-top: 2%;
  width: 300px;
  height: 200px;
}

#piechartdiv > div {
  width: 10px;
  height: 10px;
}

/* Do not take in account */
a.solink {
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  background: #f3f5f6;
  color: #cfd6d9;
  border: 1px solid #cfd6d9;
  line-height: 30px;
  text-decoration: none;
  transition: all 0.3s;
  z-index: 999;
}

a.solink::first-letter {
  text-transform: capitalize;
}

a.solink:hover {
  color: #428bca;
}

.top-div {
  display: flex;
  /* utiliser flexbox */
  align-items: center;
  /* centrer horizontalement */
  justify-content: center;
  /* centrer verticalement */
  position: relative;
}

.bottom-divs {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* changer la direction de colonne à ligne */
  width: 100%;
  /* ajouter une largeur de 80% */
  margin: 0 auto;
  /* centrer horizontalement */
  justify-content: center;
  /* centrer verticalement */
}

#sensor-instructions-container {
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  width: 80%;
  margin: auto;
}

.sensor-instructions-heading {
  font-weight: bold;
  color: #495057;
  margin-bottom: 10px;
}

.sensor-instructions-list {
  list-style-type: disc;
  margin-left: 20px;
}

.sensor-instructions-list li {
  margin-bottom: 10px;
}

.button-container {
  display: flex;
  justify-content: center;
}
.bottom-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1%;
  padding-bottom: 5%;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
}
@media(max-width:600px)
{
  .bottom-div {
    width: 50%;
    padding-bottom:15%;
    /* largeur de 100% de la div parente */
  }
}
@media (min-width: 600px) {
  /* styles pour les écrans de 600 pixels et plus de large */
  .bottom-div {
    width: 50%;
    /* largeur de 50% de la div parente */
  }
}

@media (min-width: 800px) {
  /* styles pour les écrans de 800 pixels et plus de large */
  .bottom-div {
    width: 33.3333%;
    /* largeur de 33.3333% de la div parente */
  }
}

@media (min-width: 1000px) {
  /* styles pour les écrans de 1000 pixels et plus de large */
  .bottom-div {
    width: 19%;
    /* largeur de 25% de la div parente */
  }
}
.sensor-instructions-list li {
  list-style-type: none;
}
.progress-container {
  height: 200px;
  /* Hauteur du container */
  position: relative;
  /* Pour permettre à l'élément enfant de s'positionner par rapport à lui */
  background: linear-gradient(3deg, rgb(23 21 128 / 25%) 9.4%, #239dda 78.4%);
  border-radius: 10px 10px 0px 0px;
  width: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.progress-bar {
  background-color: #cccccc;
  /* Couleur de la barre */
  height: 100%;
  /* Hauteur de la barre = hauteur du container */
  position: absolute;
  /* Pour permettre de changer la position de l'élément par rapport à son parent */
  width: 100%;
  /* Largeur de la barre = largeur du container */
  border-radius: 10px 10px 0px 0px;
}

#wrapper {
  position: relative;
}

.PlacementBoutonActionMoniteur {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.gauge {
  position: relative;
  width: 50px;
  height: 200px;
  display: inline-block;
  border-radius: 10px 10px 0px 0px;
}

.gauge-bar {
  width: 50px;
  position: absolute;
  border-top: solid;
  border-top-color: white;
}

.param-logo {
  position: absolute;
  bottom: -12px;
  right: -15px;
  width: 30px;
  height: 30px;
  background-image: url("../../../assets/img/executable_active.png");
  /* Remplacer "path/to/param-logo.png" par le chemin vers votre image */
  background-size: cover;
  cursor: pointer;
}

.gauge-indicators {
  position: absolute;
  right: 50px;
  top: 0;
  bottom: 0;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gauge-indicator {
  text-align: center;
  position: absolute;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.center-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#texteMoniteur {
  margin: 20px;
  font-size: 16px;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 15px;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: black;
  background: none;
  border: none;
}

.modal-background-cpm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content-cpm {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 40px;
  border-radius: 15px;
}


.close-button-cpm {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: black;
  background: none;
  border: none;
}

.input-container {
  display: flex;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100%);
  opacity: 0;
}

.PlacementBoutonTerminer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
  bottom: 80px;
}

.notification-container {
  position: fixed;
  top: 10;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
}

.notification-box {
  background-color: #28a745;
  color: #fff;
  padding: 30px;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
}

.notification-text {
}

.notification-close {
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  position: absolute;
  top: 0;
  right: 5px;
}

.progress-bar-notification-container {
  width: 100%;
  height: 8px;
  margin-top: 20px;
  background-color: #eee;
  border-radius: 4px;
}

.progress-bar-notification {
  height: 100%;
  background-color: rgb(195, 194, 194);
  border-radius: 4px;
  transition: width 0.3s ease-in;
}

.textMoniteur {
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  bottom: 10px;
}

.input > .input-container {
  flex: 1;
  height: 30px;
  font-size: 16px;
  text-align: center;
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
}

.plus-button,
.minus-button {
  width: 30px;
  margin: 5px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  background-color: rgb(35, 147, 128);
  border: none;
  cursor: pointer;
}

select {
  width: 600px;
  height: 35px;
  font-size: 16px;
  background-color: white;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
}

.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
}

.buttons-container > button {
  margin: 0 10px;
}

.grey {
  color: grey;
}

.circleChart {
  width: 75%;
  height: 75%;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 10px;
  font-size: 1.1vw;
}

.PercentageGauge {
  position: absolute;
  right: -30px;
  top: -15px;
}

@media (max-width: 768px) {
  /* changes styling for screens smaller than 768px */
  .circleChart {
    font-size: 12px;
  }
}

.background-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, green, orange, red);
  border-radius: 10px 10px 0px 0px;
}

.center-value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerCircleChart {
  background-color: #cccccc;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px #00000094;
}

@media (max-width: 768px) {
  /* changes styling for screens smaller than 768px */
  .containerCircleChart {
    width: 80%;
    height: 300px;
  }

  .circleChart {
    width: 60%;
    height: 60%;
  }
}

.containerCircleChart,
.circleChart {
  box-sizing: border-box;
}

#cercleBPM {
  box-shadow: 0 0 10px #00000094;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background: rgb(72, 145, 129);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 10px solid white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 0 0 10px #00000094;
  flex-wrap: wrap;
}

#imageBPM {
  height: 100%;
  width: 100%;
}

#petite-image {
  height: 50px;
  width: 50px;
  position: absolute;
  bottom: 0;
  right: 0;
  border: 2px solid red;
  border-radius: 50%;
  background-color: white;
}

#BpmInstant {
  font-size: 40px;
}

#RrInstant {
  font-size: 15px;
}

#bpm {
  font-size: 20px;
}

#rr {
  font-size: 15px;
}

#timerAffichage {
  font-size: 25px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin: auto;
  width: 70px;
  height: 70px;
  border: 3px solid rgb(35, 147, 128);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.5s;
  margin-bottom: 10px;
  background-color: white;
}

#timerAffichage:hover {
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.15);
}

#timer {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin: auto;
  width: 70px;
  height: 70px;
  border: 3px solid rgb(35, 147, 128);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.5s;
  margin-bottom: 10px;
  background-color: white !important;
  z-index: 1;
}

#timer:hover {
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.15);
}

.buttonChart {
  text-decoration: none;
  text-transform: none;
  padding: 10px;
  min-height: 40px;
  border-radius: 20px;
  border: 0 none;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
  margin: 7px;
}

.buttonChart2 {
  text-decoration: none;
  text-transform: none;
  padding: 10px;
  min-height: 40px;
  border-radius: 20px;
  border: 0 none;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
  margin: 7px;
}

.PlacementAudio {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.containerMoniteurLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loader-containerMoniteurLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.spinnerMoniteurLoader {
  border: 4px solid #f3f3f3;
  /* Light grey */
  border-top-color: #4caf50;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 0.8s linear infinite;
}

.textMoniteurLoader {
  margin-top: 10px;
  color: black;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>

<style scoped lang="scss">
.base-timer {
  position: relative;
  width: 150px;
  height: 150px;
  margin-bottom: 5%;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }

  &__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: rgb(65, 184, 131);
    }

    &.orange {
      color: orange;
    }

    &.red {
      color: red;
    }
  }

  &__label {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
  }
}

.hidden {
  display: none;
}

#circle circle {
  stroke: #ccc;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-dasharray: 282.74;
  stroke-dashoffset: 282.74;
  filter: drop-shadow(2px 2px 2px #333);
}

#circle circle.green {
  stroke: green;
  animation: border-animation 300s linear 1;
}

#circle text {
  font-size: 20px;
  fill: #333;
}

.timerInChart {
  position: absolute;
  top: 100px;
  font-size: 25px;
  font-family: Arial, sans-serif;
  color: #333;
}

.ReloadInChart {
  position: absolute;
  font-size: 25px;
  font-family: Arial, sans-serif;
  color: #333;
  cursor: pointer;
}

// .pie-chart {
//   position: relative;
//   width: 150px;
//   height: 150px;
//   margin: 50px auto;
//   border-radius: 50%;
//   overflow: hidden;
// }

// .slice {
//   position: absolute;
//   width: 300px;
//   height: 300px;
//   clip: rect(0, 150px, 150px, 150px);
// }

// .red {
//   top: 0;
//   left: 0;
//   transform: rotate(0deg);
//   background-color: red;
// }

// .yellow {
//   top: 0;
//   left: 0;
//   transform: rotate(0deg);
//   background-color: yellow;
// }

// .green {
//   top: 0;
//   left: 0;
//   transform: rotate(0deg);
//   background-color: green;
// }

// .center-value {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   font-size: 1.5em;
//   font-weight: bold;
// }
.row-cpm {
  display: flex;
  margin-bottom: 1rem;
}

.column-cpm {
  flex: 1;
  margin-right: 1rem;
}

.column-cpm:last-child {
  margin-right: 0;
}
</style>
<style lang="scss">
.bubble-dialog {
  position: relative;
  background-color: rgb(184, 184, 184);
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  right: -50px;
  top: -15px;
  text-align: center;

  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 1rem;
    width: 0;
    height: 0;
    border-bottom: 1rem solid white;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
  }
}

.ResponsiveMoniteur
{
  margin-top:40px;
}

.buttonListFullScreen
{
position: absolute;
    right: 20px;
    top: 0;
    z-index: 999;
}

@media (max-width: 768px) {
  /* changes styling for screens smaller than 768px */
.ResponsiveMoniteur
{
  margin-top:100px;

}
.bottom-divs
{
  margin-top:100px !important;
}
}

.toggle-button {
  background-color: #f5f5f5; /* Couleur de fond légère */
  border: 1px solid #ccc; /* Bordure subtile */
  padding: 8px 16px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 10px;
}

.toggle-button:hover {
  background-color: #e2e2e2; /* Effet de survol plus sombre */
  color: #000;
}

.toggle-button i {
  margin-right: 8px; /* Espace entre l'icône et le texte */
}
</style>
