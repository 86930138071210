<template>
  <div>
    <div v-if="!forCreateUser && !forTherapeute">
      <modification-utilisateur
        @refreshListUser="handleGetMyUsers"
        :selectedUser="selectedUser"
        @updateUser="handleUpdateUser"
      />
    </div>
    <div class="component-container">
      <div class="loader-container" v-if="isLoading">
        <div class="loader-wrapper">
          <loaderComponent />
          <p class="loading-text">Affectation en cours..</p>
        </div>
      </div>
      <section class="custom-section">
        <b-tabs type="is-fullwidth is-toggle" expanded size="is-medium">
          <b-tab-item label="Programme" class="custom-tab-item">
            <div class="content-wrapper">
              <div v-if="isLoadingProgrammes" class="placement-loader">
                <loaderComponent2 />
              </div>
              <personnalisationProgrammes
                @update-addedFolderIds="updateAddedFolderIds"
                @update-removedFolderIds="updateRemovedFolderIds"
                :selectedUser="selectedUser"
                v-for="theme in themes"
                :key="theme.id"
                :theme="theme"
                :foldersBrique="foldersBrique"
                @loading="handleLoading"
              />
            </div>
          </b-tab-item>
          <b-tab-item label="Ressources Libres" class="custom-tab-item">
            <div class="content-wrapper">
              <personnalisationRessources
                @update-addedFolderIds="updateAddedFolderIds"
                @update-removedFolderIds="updateRemovedFolderIds"
                :selectedUser="selectedUser"
                v-for="theme in themes"
                :key="theme.id"
                :theme="theme"
                :foldersBrique="foldersBrique"
              />
            </div>
          </b-tab-item>
          <b-tab-item label="Échelles d'évaluation" class="custom-tab-item">
            <div class="content-wrapper">
              <personnalisationEchelles
                @update-addedFolderIds="updateAddedFolderIds"
                @update-removedFolderIds="updateRemovedFolderIds"
                :selectedUser="selectedUser"
                v-for="theme in themes"
                :key="theme.id"
                :theme="theme"
                :foldersBrique="foldersBrique"
              />
            </div>
          </b-tab-item>
        </b-tabs>
      </section>
      <!-- <div v-if="!forCreateUser" class="component-footer right-align">
        <div>
        <p>
          <i class="fas fa-info-circle"></i> Pour valider les modifications que
          vous venez d'effectuer, n'oubliez pas de sauvegarder via le bouton
          ci-dessous.
        </p>
        <button @click="saveChanges" class="button buttonSaveChanges">
          Sauvegarder les changements
        </button>
      </div> -->
      <div
          class="navbar is-fixed-bottom"
          style="
             position: float;
                padding-top: 10px;
                padding-bottom: 10px;
                border-top: 5px;
                margin-left: 0px;
                background-color: #6753be;
                display: flex;
                justify-content: center;
          "
        >
          <div class="navbar-end">
            <a
              class="button-mon-profil button button-is-green is-margin-r-20"
              @click="saveChanges"
              type="is-success"
            >
              Sauvegarder les changements
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const User = createNamespacedHelpers("user");
import { createNamespacedHelpers } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import ParseConfig from "@/api/parse.config.js";
import Parse from "parse";

import personnalisationProgrammes from "../personnalisationAcces/personnalisationProgrammes.vue";
import personnalisationRessources from "../personnalisationAcces/personnalisationRessources.vue";
import personnalisationEchelles from "../personnalisationAcces/personnalisationEchelles.vue";
import modificationUtilisateur from "../utilisateur/NewModificationUtilisateur.vue";

import Swal from "sweetalert2/dist/sweetalert2.js";

import loaderComponent from "../loader/loaderLotus.vue";
import loaderComponent2 from "../loader/loaderComponent.vue";
export default {
  data() {
    return {
      isProgramActive: false,
      isResourcesActive: false,
      isScalesActive: false,
      foldersBrique: [],
      themes: [],
      isLoadingProgrammes: true,
      addedFolderIds: [],
      removedFolderIds: [],
      isLoading: false,
      UserForUpdate: null,
    };
  },

  props: {
    selectedUser: {
      type: Object,
      default: () => {},
    },
    forCreateUser: {
      type: Boolean,
      required: false,
      default: false,
    },
    forTherapeute: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created() {
    this.foldersBrique = cloneDeep(
      this.folders.filter((e) => e.domaine == "Santé")
    );
    this.getTheme();
    // this.getFoldersTherapeute();
  },
  components: {
    personnalisationProgrammes,
    loaderComponent,
    personnalisationRessources,
    personnalisationEchelles,
    loaderComponent2,
    modificationUtilisateur,
  },
  computed: {
    ...User.mapGetters(["group", "user", "folders"]),
    filteredRessources() {
      return this.foldersBrique.filter(
        ({ name }) =>
          name &&
          (name.includes("cardiaque") ||
            name.includes("hypnotique") ||
            name.includes("compassion"))
      );
    },
  },
  methods: {
    updateAddedFolderIds(newVal) {
      // Retirez les valeurs de newVal de removedFolderIds si elles y sont
      this.removedFolderIds = this.removedFolderIds.filter(
        (id) => !newVal.includes(id)
      );

      // Ajoutez les nouvelles valeurs à addedFolderIds
      this.addedFolderIds = [...new Set([...this.addedFolderIds, ...newVal])];

      //console.log("removedFolderIds", this.removedFolderIds);
      //console.log("addedFolderIds", this.addedFolderIds);
    },
    handleUpdateUser(e) {
      console.log("UserForUpdate", e);
      this.UserForUpdate = e;
    },
    async getFoldersTherapeute() {
      var userTherapeute = Parse.User.current();
      var linkRelation = userTherapeute.relation("link");
      var linkQuery = linkRelation.query();

      linkQuery.include("folder");

      var folders = await linkQuery.find();

      folders = folders.map((link) => link.get("folder"));

      console.log("foldersTherapeute", folders);
    },
    UpdateUser(selectedUser) {
      ParseConfig();
      //transforme la durée accès en nubmer avant
      Parse.Cloud.run("modifyUserDetails", {
        userObjectId: selectedUser.objectId,
        nom: selectedUser.nom,
        prenom: selectedUser.prenom,
        mail: selectedUser.mail,
        duree_acces: Number(selectedUser.dureeAcces),
        TherapeuteObjectId: Parse.User.current().id,
      })
        .then((response) => {
          console.log("Successfully updated user:", response);
        })
        .catch((error) => {
          console.log("Failed to update user", error);
        });
      console.log("User sélectionné:", this.selectedUser);
      var dureeAcces = this.selectedUser.dureeAcces;
      //convertir en chiffre
      dureeAcces = Number(dureeAcces);
      var groupUser = Parse.User.current().attributes.group;
      var dureeAccesInitial = this.selectedUser.dureeAccesInitial;
      var  credits_a_retirer = dureeAcces - dureeAccesInitial;
      if(credits_a_retirer > 0)
      {
        var Group = Parse.Object.extend("user_group");
        var queryGroup = new Parse.Query(Group);
        queryGroup.equalTo("objectId", groupUser.id);
        queryGroup.find().then((group) => {
          console.log("Groupe:", group);
          var NbCredits = group[0].attributes.credits;  
          console.log("Nombre de crédits", NbCredits);
          console.log("Durée d'accès:", dureeAcces);
          // retirer les crédits au groupe par rapport à durée d'accès
          var newNbCredits = NbCredits - credits_a_retirer;
          console.log("Nouveau nombre de crédits", newNbCredits);
          group[0].set("credits", newNbCredits);
          group[0].save();
          console.log("Crédits retirés");
        });
      }
      
    },
    updateRemovedFolderIds(newVal) {
      // Retirez les valeurs de newVal de addedFolderIds si elles y sont
      this.addedFolderIds = this.addedFolderIds.filter(
        (id) => !newVal.includes(id)
      );

      // Ajoutez les nouvelles valeurs à removedFolderIds
      this.removedFolderIds = [
        ...new Set([...this.removedFolderIds, ...newVal]),
      ];
      if (this.forCreateUser) {
        this.$emit("update-addedFolderIds", this.addedFolderIds);
      }
      //console.log("removedFolderIds", this.removedFolderIds);
      //console.log("addedFolderIds", this.addedFolderIds);
    },
    getFoldersByTheme(monTheme) {
      return this.foldersBrique.filter((el) => {
        if (el.themes.length < 2) {
          return el.themes[0].objectId === monTheme;
        }
        return (
          el.themes[0].objectId === monTheme ||
          el.themes[1].objectId === monTheme
        );
      });
    },
    async fetchUser(objectId) {
      const userParse = Parse.Object.extend("User");
      const userQuery = new Parse.Query(userParse);
      userQuery.equalTo("objectId", objectId);

      try {
        return await userQuery.first();
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de l'utilisateur:",
          error
        );
        throw error;
      }
    },
    test()
    {
      console.log("test");
    },
    handleGetMyUsers() {
      this.$emit("refreshListUser");
    },
    async saveChanges() {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 9000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      try {
        this.isLoading = true;

        const user = await this.fetchUser(this.selectedUser.objectId);
        const linkRelation = user.relation("link");

        let linksToRemove = [];
        let linksToAdd = [];

        // Utilisation de Promise.all pour attendre que toutes les opérations soient terminées
        await Promise.all(
          this.removedFolderIds.map(async (folderId) => {
            // Rechercher le folder qui a cet id
            var folderQuery = new Parse.Query("folder");
            folderQuery.equalTo("objectId", folderId);
            const folderParseObj = await folderQuery.first();

            if (folderParseObj) {
              // Vérifier que folderParseObj existe
              // Rechercher le link associé à ce folder
              let linkQuery = linkRelation.query();
              linkQuery.equalTo("folder", folderParseObj);
              const link = await linkQuery.first();

              if (link) {
                // Vérifier que l'objet link existe
                linksToRemove.push(link.id);
              }
            }

            var fileQuery = new Parse.Query("file");
            fileQuery.equalTo("objectId", folderId);
            const file = await fileQuery.first();
            if (file) {
              // Vérifier que folderParseObj existe
              // Rechercher le link associé à ce folder
              let linkQuery = new Parse.Query("link");
              linkQuery.equalTo("file", file);
              const link = await linkQuery.first();
              if(link)
              {
                linksToRemove.push(link.id);
              }
            }
          })
        );
        await Promise.all(
          this.addedFolderIds.map(async (folderId) => {
            // Rechercher le folder qui a cet id
            var folderQuery = new Parse.Query("folder");
            folderQuery.equalTo("objectId", folderId);
            const folderParseObj = await folderQuery.first();
            if (folderParseObj) {
              // Vérifier que folderParseObj existe
              // Rechercher le link associé à ce folder
              let linkQuery = new Parse.Query("link");
              linkQuery.equalTo("folder", folderParseObj);
              const link = await linkQuery.first();

              if (link) {
                // Vérifier que l'objet link existe
                linksToAdd.push(link.id);
              }
            }

            var fileQuery = new Parse.Query("file");
            fileQuery.equalTo("objectId", folderId);
            const file = await fileQuery.first();
            if (file) {
              // Vérifier que folderParseObj existe
              // Rechercher le link associé à ce folder
              let linkQuery = new Parse.Query("link");
              linkQuery.equalTo("file", file);
              const link = await linkQuery.first();
              if(link)
              {
                linksToAdd.push(link.id);
              }
            }

          })
        );

        //console.log("Voici le tableau des links à supprimer:", linksToRemove);
        //console.log("Voici le tableau des links à ajouter:", linksToAdd);
        // Appeler la fonction Parse Cloud pour modifier les relations de lien
        await Parse.Cloud.run("modifyUserLinkRelation", {
          userObjectId: this.selectedUser.objectId,
          linksToRemove: linksToRemove,
          linksToAdd: linksToAdd,
        });
        if (!this.forCreateUser && !this.forTherapeute && this.UserForUpdate) {
          this.UpdateUser(this.UserForUpdate);
        }
        this.isLoading = false;
        //console.log("Modifications enregistrées avec succès.");
        Toast.fire({
          icon: "success",
          title: "Les modifications ont été enregistrées avec succès.",
        });
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: "Une erreur est survenue lors de l'enregistrement.",
        });
        this.isLoading = false;
        console.error("Une erreur est survenue:", error);
      }
    },
    handleLoading(e) {
      console.log('Handle Loading:', e);
      this.isLoadingProgrammes = e;
    },
    async getTheme() {
      const oDomaine = Parse.Object.extend("domaine");
      const queryDomaine = new Parse.Query(oDomaine);
      queryDomaine.equalTo("nom", "Santé");
      const domaineSante = await queryDomaine.first();
      const oTheme = Parse.Object.extend("theme");
      const queryTheme = new Parse.Query(oTheme);
      queryTheme.equalTo("domaine", domaineSante);
      queryTheme.ascending("ordre");
      this.themes = await queryTheme.find();
      //console.log("themes", this.themes);
    },
  },
  watch: {
    isProgramActive(val) {
      console.log("Programmes sont", val ? "activés" : "désactivés");
    },
    isResourcesActive(val) {
      console.log("Ressources libres sont", val ? "activées" : "désactivées");
    },
    isScalesActive(val) {
      console.log("Échelles d'évaluation sont", val ? "activées" : "désactivées");
    },
  },
};
</script>

<style scoped lang="scss">
.custom-section {
  padding: 20px;
  background-color: #f2f2f2;
}

.custom-tab-item {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  .label, // Cible la classe de label si elle existe
  span {
    // Ou cible simplement tous les éléments <span> à l'intérieur de .custom-tab-item
    font-size: 24px; // Définir la taille de la police
    font-weight: bold; // Rendre le texte en gras
  }
}
.custom-tab-item {
  font-size: 24px; /* Augmente la taille de la police */
  font-weight: bold; /* Rend le texte en gras */
}
.content-wrapper {
  padding: 20px;
  h2 {
    margin-bottom: 20px;
  }
  .title {
    color: #333;
  }
}
.nom-prenom-div {
  display: flex;
}
.element {
  flex: 1;
  margin-right: 20px;
}
.placement-loader {
  display: flex;
  justify-content: center;
}
.component-container {
  position: relative;
  /* Hauteur dépend de votre besoin, vous pouvez l'ajuster */
 /* height: calc(100vh - 100px);
  overflow-y: auto; /*
}

.component-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  text-align: center;
  padding: 15px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
}
.component-container {
  position: relative;
  height: calc(100vh - 100px);
  overflow-y: auto;
  /* Personnalisation de la scrollbar pour les navigateurs WebKit */
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #7957d5;
    outline: 1px solid slategrey;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #6c4dc6; /* Couleur plus foncée lorsque le curseur est dessus */
  }

  /* Personnalisation de la scrollbar pour Firefox */
  scrollbar-width: thin;
  scrollbar-color: #7957d5 slategrey;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
}

.loader-wrapper {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.right-align {
    display: flex;
    justify-content: center;
}

.buttonSaveChanges {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  background-color: #239380;
  color: white;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #239380; /* Utilisation de votre code couleur */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.loading-text {
  color: #239380; /* Utilisation de votre code couleur */
  font-size: 2rem;
  font-weight: 500;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button-is-green
{
  background-color: #239380;
  color: white;
}

.navbar-end
{
  margin-left: auto;
  margin-right: auto;
}
</style>
