<template>
  <div class="gauge-container">
    <div class="gauge">
      <div
        v-for="n in number"
        :key="n"
        class="gauge-divider"
        :style="{ left: n * number + '%' }"
      ></div>
    </div>
    <div class="gauge-labels" v-if="localResponses.length===10">
      <div
        v-for="n in number"
        :key="n"
        class="gauge-label"
        :style="{ left: (n - 1) * number + '%' }"
      >
        <div>{{ n }}</div>
        <template v-if="isForm">
          <input
            class="response"
            v-bind:class="{ 'selected-response': selected === n }"
            v-model="localResponses[n - 1].text"
            :placeholder="'Réponse ' + n"
            @input="updateResponse()"
            @click="selectResponse(n, localResponses[n - 1].text)"
          />
        </template>
        <template v-else>
          <textarea
            class="response"
            v-bind:class="{ 'selected-response': selected === n }"
            v-model="localResponses[n - 1].text"
            :placeholder="'Réponse ' + n"
            @input="updateResponse()"
            @click="selectResponse(n, localResponses[n - 1].text)"
            rows="2"
            readonly
          ></textarea>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HorizontalGauge",
  props: {
    responses: {
      type: Array,
      default: () => [
        { nombre: 1, texte: "" },
        { nombre: 2, texte: "" },
        { nombre: 3, texte: "" },
        { nombre: 4, texte: "" },
        { nombre: 5, texte: "" },
        { nombre: 6, texte: "" },
        { nombre: 7, texte: "" },
        { nombre: 8, texte: "" },
        { nombre: 9, texte: "" },
        { nombre: 10, texte: "" },
      ],
    },
    scaleIndex: {
      type: Number,
      default: 0,
    },
    isForm: {
      type: Boolean,
      default: false,
    },
    onTestRef: {
      type: Function,
    }
  },
  data() {
    return {
      localResponses: [...this.responses],
      selected: null,
      number : null,
    };
  },
  created() {
     console.log('Local responses', this.localResponses)
     var ResponsesLenght = this.localResponses.length;
     console.log('Local responses length', ResponsesLenght)
     if(ResponsesLenght < 0) {
       this.number = 10;
     }
     else
     {
     this.number = ResponsesLenght;
     }
  },
  watch: {
    responses: {
      handler() {
        this.resetSelectedResponse();
      },
      deep: true,
    },
  },
  methods: {
    testRef()
    {
      console.log('yooo');
    },
    resetSelectedResponse() {
      console.log("resetSelectedResponse");
      this.selected = null;
    },
    updateResponse() {
      this.$emit("response-updated", this.localResponses, this.scaleIndex);
    },
    selectResponse(number, name) {
      this.selected = number;
      this.$emit("response-selected", { number, name });
    },
  },
};
</script>
<style scoped>
.gauge-container {
  width: 100%;
  position: relative;
}
.gauge {
  background: linear-gradient(to right, #00ff00, #ffff00, #ff0000);
  height: 60px;
  position: relative;
  cursor: pointer;
}
.gauge-divider {
  position: absolute;
  top: 0;
  bottom: 0;
  border-left: 1px solid #000;
  opacity: 0.5;
}
.gauge-labels {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.gauge-label {
  position: absolute;
  bottom: -170px;
  width: 10%;
  text-align: center;
}
.number {
  font-weight: bold;
}
.response {
  background-color: white;
  border: 1px solid #000;
  padding: 2px 5px;
  margin-top: 5px;
  font-size: 0.8em;
  display: block;
  resize: none;
  overflow: hidden;
  max-width: 100%;
  height: 120px;
  cursor: pointer;
}
.selected-response {
  background-color: #a0d6ff;
}
</style>
