var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.showNotification)?_c('div',{staticClass:"notification-container"},[_c('div',{staticClass:"notification-box"},[_c('button',{staticClass:"notification-close",on:{"click":_vm.closeNotification}},[_vm._v(" × ")]),_c('div',{staticClass:"notification-text"},[_vm._v("Exercice terminé")])])]):_vm._e()])],1),_vm._l((_vm.questionnaireBFP),function(item){return _c('div',{key:item.id,staticClass:"card-container-bfp"},[(!_vm.isForBeginPage)?_c('div',{staticClass:"card-bfp",style:(_vm.questionnaireSelectedId === item.id && _vm.displayQuestionnaire
          ? 'background-color: #489181'
          : ''),on:{"click":function($event){return _vm.openForm(item)}}},[_c('div',{staticClass:"card-content-bfp"},[_c('div',{staticClass:"card-icon-bfp"},[(_vm.questionnaireSelectedId === item.id && _vm.displayQuestionnaire)?_c('img',{attrs:{"src":require("../../assets/img/icon-bfp-white.png")}}):_c('img',{attrs:{"src":require("../../assets/img/icon-bfp.png")}})]),_c('div',{staticClass:"card-text-bfp"},[_c('h2',{style:(_vm.questionnaireSelectedId === item.id && _vm.displayQuestionnaire
                ? 'color:white'
                : '')},[_vm._v(" "+_vm._s(item.attributes.name)+" ")]),(_vm.questionnaireSelectedId === item.id && _vm.displayQuestionnaire)?_c('img',{staticClass:"chevron-down-bfp",attrs:{"src":require("../../../../assets/img/ChevronUpBlancV2.png")}}):_c('img',{staticClass:"chevron-down-bfp",attrs:{"src":require("../../../../assets/img/ChevronDownV2.png")}})])])]):_vm._e(),(
        _vm.displayQuestionnaire &&
        _vm.questions.length > 0 &&
        _vm.questionnaireSelectedId === item.id
      )?_c('div',{staticClass:"questions"},[(_vm.displayQuestionnaire && _vm.currentQuestion)?_c('div',{staticClass:"question"},[_c('h3',{staticClass:"title is-4"},[_vm._v(_vm._s(_vm.currentQuestion.name))]),_c('horizontal-gauge',{key:_vm.refreshKey,ref:"horizontalGauge",refInFor:true,attrs:{"responses":_vm.currentQuestion.answers},on:{"response-selected":function($event){_vm.confirmResponse(_vm.questions.indexOf(_vm.currentQuestion), $event)}}})],1):_vm._e(),(_vm.selectedResponse)?_c('div',{staticClass:"SelectedResponse"},[_c('p',[_vm._v(" Vous avez choisi la réponse "),_c('strong',[_vm._v(_vm._s(_vm.selectedResponse.responseNumber))]),_vm._v(" , cela correspond à "),_c('strong',[_vm._v(_vm._s(_vm.selectedResponse.responseName))]),_vm._v(" . ")]),_c('p',[_vm._v(" Êtes-vous sur que ce n'est pas "),_c('strong',[_vm._v(_vm._s(_vm.getAdjacentNumbers(_vm.selectedResponse.responseNumber)))])]),_c('p',[_vm._v("Êtes-vous sûr de vouloir valider cette réponse ?")]),_c('button',{staticClass:"button is-danger",on:{"click":function($event){return _vm.cancelResponse()}}},[_vm._v(" Non ")]),_c('button',{staticClass:"button is-success",on:{"click":function($event){return _vm.validateResponse(_vm.selectedResponse.responseNumber)}}},[_vm._v(" Oui ")])]):_vm._e()]):_vm._e(),(_vm.displayQuestionnaire && !_vm.currentQuestion && !_vm.isForBeginPage)?_c('div',{staticClass:"score-results"},[_c('div',{staticClass:"result-indicator",class:_vm.resultbfp}),_c('div',{staticClass:"score-details"},[_c('h3',{staticClass:"title is-4"},[_vm._v("Score total : "+_vm._s(_vm.getScoreTotal()))]),_c('p',[_vm._v(" Bilan : "),_c('strong',[_vm._v(_vm._s(_vm.getBilan(_vm.getScoreTotal())))]),_c('br'),_vm._v(" État : "),_c('strong',[_vm._v(_vm._s(_vm.textresultbfp))])])])]):_vm._e()])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }