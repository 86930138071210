<template lang="html">
  <section class="hero is-fullheight">
    <div class="is-margin-10">
      <div class="columns has-text-centered">
        <div class="column is-12">
          <h3 class="title has-text-grey is-size-4">
            {{ $t("souscrivezOffrePulse") }}
          </h3>
          <div class="box" v-if="!goToPaiement">
            <p class="is-size-3 is-margin-b-20">1 – {{ $t("choixForfait") }}</p>

            <div class="columns is-multiline">
              <div
                class="column is-12-mobile is-6-tablet is-6-desktop is-6-widescreen has-text-centered"
              >
                <a
                  class="button is-blueregister is-large button-inscription-offre-abonnement-pulse"
                  :class="{ isGrey: !abonnement.engagementAnnuel }"
                  @click="
                    abonnement.engagementAnnuel = true;
                    contratAccepte = false;
                  "
                >
                  <div class="columns is-multiline">
                    <div class="column is-12 button-text-offre-abonnement">
                      <p class="is-size-6">{{ $t("avecEngagementAnnuel") }} </p>
                    </div>
                    <div class="column is-12 button-text-offre-abonnement">
                      <p class="is-size-4">
                        {{ (this.pulseAnnuel / 12).toFixed(2) }}€ /
                        {{ $t("mois") }} ttc
                      </p>
                    </div>
                    <div class="column is-12 button-text-offre-abonnement">
                      <figure
                        class="image is-2by1"
                        style="display: flex; padding-top: 25% !important"
                      >
                        <img
                          style="
                            max-width: 400px;
                            max-height: 600px;
                            margin-left: auto;
                            margin-right: auto;
                          "
                          src="../../../assets/img/device_symbiocenter.png"
                          alt="Image abonnement pulse"
                        />
                      </figure>
                      <p class="is-size-7">
                        <span class="has-text-success">
                          {{ $t("economieDe") }} ({{ this.pulseEconomie }})
                        </span>
                        <br />
                        {{ $t("prelevementAnnuelDe") }}
                        {{ this.pulseAnnuel.toFixed(2) }} € ttc. <br />
                        {{ $t("debiteEn") }} 1 {{ $t("fois") }}.
                      </p>
                      <p class="has-text-success is-size-6">
                        {{ $t("capteurInclus") }}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                class="column is-12-mobile is-6-tablet is-6-desktop is-6-widescreen has-text-centered"
              >
                <a
                  class="button is-blueregister is-large button-inscription-offre-abonnement-pulse"
                  :class="{ isGrey: abonnement.engagementAnnuel }"
                  @click="
                    abonnement.engagementAnnuel = false;
                    contratAccepte = false;
                  "
                >
                  <div class="columns is-multiline">
                    <div class="column is-12 button-text-offre-abonnement">
                      <p class="is-size-6">{{ $t("sansEngagement") }}</p>
                    </div>
                    <div class="column is-12 button-text-offre-abonnement">
                      <p class="is-size-4">
                        {{ this.pulseMensuel.toFixed(2) }}€ /
                        {{ $t("mois") }} ttc
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <p class="is-size-3 is-margin-b-20">
              2 – {{ $t("vosInformationsProfessionnelles") }}
            </p>
            <form>
              <b-field
                :type="{ 'is-danger': !user.civilite && error }"
                :message="{
                  'Veuillez sélectionner votre civilite':
                    !user.civilite && error,
                }"
              >
                <b-select
                  placeholder="Civilité"
                  v-model="user.civilite"
                  expanded
                  @input="contratAccepte = false"
                >
                  <option
                    v-for="civilite in civilites"
                    :value="civilite"
                    :key="civilite"
                  >
                    {{ civilite }}
                  </option>
                </b-select>
              </b-field>
              <b-field
                :type="{ 'is-danger': !user.nom && error }"
                :message="{
                  'Veuillez saisir votre nom de famille': !user.nom && error,
                }"
              >
                <b-input
                  placeholder="Nom de famille"
                  v-model="user.nom"
                  @input="contratAccepte = false"
                >
                </b-input>
              </b-field>
              <b-field
                :type="{ 'is-danger': !user.prenom && error }"
                :message="{
                  'Veuillez saisir votre prénom': !user.prenom && error,
                }"
              >
                <b-input
                  placeholder="Prénom"
                  v-model="user.prenom"
                  @input="contratAccepte = false"
                ></b-input>
              </b-field>
              <b-field
                :type="{ 'is-danger': !user.profession && error }"
                :message="{
                  'Veuillez saisir votre profession': !user.profession && error,
                }"
              >
                <b-input
                  placeholder="Profession"
                  v-model="user.profession"
                  @input="contratAccepte = false"
                >
                </b-input>
              </b-field>
              <b-field
                :type="{ 'is-danger': !user.adresse && error }"
                :message="{
                  'Veuillez saisir votre adresse professionnelle':
                    !user.adresse && error,
                }"
              >
                <b-input
                  placeholder="Adresse professionelle"
                  v-model="user.adresse"
                  @input="contratAccepte = false"
                >
                </b-input>
              </b-field>
              <b-field>
                <b-input
                  placeholder="Complément d'adresse"
                  v-model="user.complementAdresse"
                  @input="contratAccepte = false"
                >
                </b-input>
              </b-field>
              <div class="columns">
                <div class="column is-6">
                  <b-field
                    :type="{ 'is-danger': !user.codePostal && error }"
                    :message="{
                      'Veuillez saisir votre code postal':
                        !user.codePostal && error,
                    }"
                  >
                    <b-input
                      placeholder="Code postal"
                      v-model="user.codePostal"
                      @input="contratAccepte = false"
                    >
                    </b-input>
                  </b-field>
                </div>
                <div class="column is-6">
                  <b-field
                    :type="{ 'is-danger': !user.ville && error }"
                    :message="{
                      'Veuillez saisir votre code postal': !user.ville && error,
                    }"
                  >
                    <b-input
                      placeholder="Ville"
                      v-model="user.ville"
                      @input="contratAccepte = false"
                    ></b-input>
                  </b-field>
                </div>
              </div>
              <b-field
                :type="{ 'is-danger': !user.telephone_pro && error }"
                :message="{
                  'Veuillez saisir votre téléphone professionel':
                    !user.telephone_pro && error,
                }"
              >
                <b-input
                  placeholder="Téléphone professionel"
                  v-model="user.telephone_pro"
                  @input="contratAccepte = false"
                >
                </b-input>
              </b-field>
              <b-field
                :type="{
                  'is-danger':
                    ((!user.mail || errorMessage) && error) ||
                    errorEmailFormat ||
                    errorEmailUse ||
                    errorMajuscule,
                }"
                :message="{
                  'Veuillez saisir votre mail professionel':
                    !user.mail && error,
                  'Format de l\'email incorrect': errorEmailFormat,
                  'Email déjà utilisé': errorEmailUse,
                  'L\'email ne doit pas contenir de majuscule': errorMajuscule,
                }"
              >
                <b-input
                  placeholder="Mail professionel"
                  v-model="mailUser"
                  id="copy"
                  @focus="doNotCopyPast('copy')"
                  @input="contratAccepte = false"
                >
                </b-input>
              </b-field>
              <b-field
                :type="{
                  'is-danger':
                    (!mailCopy && error) || errorSamePassword || errorMajuscule,
                }"
                :message="{
                  'Veuillez recopier votre mail professionel':
                    !mailCopy && error,
                  'Il faut que votre adresse email soit identique à la précédente':
                    errorSamePassword && error,
                  'L\'email ne doit pas contenir de majuscule': errorMajuscule,
                }"
              >
                <b-input
                  placeholder="Recopier email"
                  v-model="mailCopy"
                  id="past"
                  @focus="doNotCopyPast('past')"
                  @input="contratAccepte = false"
                >
                </b-input>
              </b-field>
              <b-field>
                <b-radio-button
                  v-model="siretOrAdeli"
                  native-value="Siret"
                  type="is-primary"
                  @input="contratAccepte = false"
                >
                  <span>{{ $t("siret") }}</span>
                </b-radio-button>
                <b-radio-button
                  v-model="siretOrAdeli"
                  native-value="Adeli"
                  type="is-primary"
                  @input="contratAccepte = false"
                >
                  <span>{{ $t("adeli") }}</span>
                </b-radio-button>
              </b-field>
              <b-field
                v-if="siretOrAdeli === 'Siret'"
                :type="{
                  'is-danger':
                    (!user.siret && error && siretOrAdeli === 'Siret') ||
                    errorSiret,
                }"
                :message="{
                  'Veuillez saisir votre n° SIRET':
                    !user.siret && error && siretOrAdeli === 'Siret',
                  'Numero de siret incorrect': errorSiret,
                }"
              >
                <b-input
                  placeholder="n° SIRET"
                  class="is-margin-b-20"
                  v-model="user.siret"
                  @input="contratAccepte = false"
                >
                </b-input>
              </b-field>
              <b-field
                v-if="siretOrAdeli === 'Adeli'"
                :type="{
                  'is-danger':
                    (!user.adeli && error && siretOrAdeli === 'Adeli') ||
                    errorAdeli,
                }"
                :message="{
                  'Veuillez saisir votre n° ADELI':
                    !user.adeli && error && siretOrAdeli === 'Adeli',
                  'Numero de siret incorrect': errorAdeli,
                }"
              >
                <b-input
                  placeholder="n° ADELI"
                  class="is-margin-b-20"
                  v-model="user.adeli"
                  @input="contratAccepte = false"
                >
                </b-input>
              </b-field>
              <p
                class="is-size-3 is-margin-b-20"
                v-if="abonnement.engagementAnnuel"
              >
                3 – {{ $t("votreCapteurInclus") }}
              </p>

              <div v-if="abonnement.engagementAnnuel" class="columns">
                <div class="column is-6">
                  <img
                    class="image-capteur"
                    src="../../../assets/img/capteur_KYTO-300px.png"
                  />
                </div>
                <div class="column is-6 has-text-justified">
                  <p>
                    {{ $t("capteurInformation") }}
                  </p>
                  <br />
                  <div class="field">
                    <b-checkbox
                      class="is-margin-b-20"
                      v-model="abonnement.cartesPostales"
                      @input="contratAccepte = false"
                    >
                      {{ $t("souhaiteRecevoirCartesPostales") }}
                    </b-checkbox>
                    <div>
                      {{ $t("cartePostalConfirmationAdresse") }}
                    </div>
                  </div>
                </div>
              </div>

              <adresseLivraisonComponent
                v-if="abonnement.engagementAnnuel"
                @updateAdresse="updateAdresse"
                :propsInformationAdresse="abonnement.adresse"
                :error="error"
              />
              <br />

              <b-field
                :type="{ 'is-danger': !contratAccepte && errorContratAccepte }"
                :message="{
                  'Veuillez accepter le contrat avant de confirmer votre abonnement.':
                    !contratAccepte && errorContratAccepte,
                }"
              >
                <b-checkbox v-model="contratAccepte">
                  {{ $t("cgu") }} (<a
                    href="https://www.symbiocenter.com/assets/CGV_SymbioCenter.pdf"
                    target="_blank"
                    >{{ $t("cgu2_cgv") }}</a
                  >,<a
                    href="https://www.symbiocenter.com/assets/CP_Pulse_SymbioSensor.pdf"
                    target="_blank"
                    >{{ $t("cgu2_cp") }}</a
                  >,<a
                    href="https://www.symbiocenter.com/assets/CGU_P_SymbioCenter.pdf"
                    target="_blank"
                    >{{ $t("cgu2_cgu") }}</a
                  >,<a
                    href="https://www.symbiocenter.com/assets/RGPD_SymbioCenter.pdf"
                    target="_blank"
                  >
                    {{ $t("cgu2_rgpd") }}</a
                  >)
                </b-checkbox>
              </b-field>

              <div>
                <p
                  class="has-text-weight-light has-text-danger is-size-6"
                  v-if="error && errorMessage"
                >
                  {{ errorMessage }}
                </p>
              </div>

              <a
                v-if="contratAccepte && !error && tpeIsReady"
                class="button is-block is-primary is-fullwidth"
                @click="goToPaiement = true"
              >
                {{ $t("procederAuPaiement") }}
              </a>
            </form>
          </div>
          <div
            v-else-if="contratAccepte && !error && tpeIsReady && goToPaiement"
          >
            <p class="has-text-weight-bold">
              {{ $t("abonnement") }}
              {{ abonnement.engagementAnnuel ? "annuel" : "mensuel" }}
            </p>
            <p class="has-text-weight-bold">
              {{ $t("montantARegler") }} :
              {{
                abonnement.engagementAnnuel
                  ? this.pulseAnnuel.toFixed(2)
                  : this.pulseMensuel.toFixed(2)
              }}
              €
            </p>
            <formulaire-paiement-component
              :transactionData2="transactionData"
              :code_societe="code_societe"
              :amount="transactionData.amount"
              :page="'inscriptionpulse'"
              :user="user"
            />
            <a class="button is-grey" @click="retourPaiement()">
              {{ $t("retour") }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Parse from "parse";
import axios from "axios";
import ParseConfig from "@/api/parse.config.js";
import createUser from "@/services/utilisateurs/user.js";
import { URL_API_SIRET } from "@/configuration/urlConf.js";
import codeGenerator from "@/services/general/codeGenerator.js";
import AdresseLivraison from "@/components/general/AdresseLivraison.vue";
import { checkAddressInformation } from "@/services/general/checkAddressInformation.js";
import FormulairePaiement from "@/components/general/FormulairePaiement.vue";
import router from "@/router";
import { CONFIG_PAIEMENT } from "@/configuration/paiementConf.js";
import asyncForEach from "@/services/general/asyncForEach.js";
import jsPDF from "jspdf";
import moment from "moment";

export default {
  name: "inscription",
  components: {
    AdresseLivraisonComponent: AdresseLivraison,
    FormulairePaiementComponent: FormulairePaiement,
  },
  data() {
    return {
      user: {},
      mailUser: null,
      mailCopy: null,
      errorSamePassword: false,
      abonnement: {
        informationsBancaires: {
          typePaiement: null,
          numeroCarte: null,
          dateExpiration: null,
          codePostal: null,
          pays: null,
          numeroTva: null,
          formatDateValid: true,
        },
        adresse: {},
        engagementAnnuel: true,
        cartesPostales: false,
      },
      adresseLivraisonDifferente: false,
      civilites: [
        "Madame",
        "Monsieur",
        "Docteur (M)",
        "Docteur (Mme)",
        "Professeur (M)",
        "Professeur (Mme)",
        "Maître (M)",
        "Maître (Mme)",
      ],
      error: false,
      errorMessage: null,
      hasSubscription: false,
      errorSiret: false,
      errorAdeli: false,
      errorEmailFormat: false,
      errorEmailUse: false,
      errorMajuscule: false,
      siretOrAdeli: "Siret",
      contratAccepte: false,
      errorContratAccepte: false,
      isPaiement: false,
      transactionData: null,
      datePaiement: null,
      amount: null,
      code_societe: CONFIG_PAIEMENT.CODE_SOCIETE_MENSUEL,
      tpeIsReady: false,
      userCreate: {},
      goToPaiement: false,
      userGroup: {},
      pulseMensuel: 0,
      pulseAnnuel: 0,
      pulseEconomie: "",
    };
  },
  computed: {},
  methods: {
    retourPaiement() {
      this.user = {};
      this.mailUser = null;
      this.mailCopy = null;
      this.errorSamePassword = false;
      this.abonnement = {
        informationsBancaires: {
          typePaiement: null,
          numeroCarte: null,
          dateExpiration: null,
          codePostal: null,
          pays: null,
          numeroTva: null,
          formatDateValid: true,
        },
        adresse: {},
        engagementAnnuel: true,
        cartesPostales: false,
      };
      this.adresseLivraisonDifferente = false;
      this.error = false;
      this.errorMessage = null;
      this.hasSubscription = false;
      this.errorSiret = false;
      this.errorAdeli = false;
      this.errorEmailFormat = false;
      this.errorEmailUse = false;
      this.siretOrAdeli = "Siret";
      this.contratAccepte = false;
      this.errorContratAccepte = false;
      this.isPaiement = false;
      this.transactionData = null;
      this.datePaiement = null;
      this.amount = null;
      this.code_societe = CONFIG_PAIEMENT.CODE_SOCIETE_MENSUEL;
      this.tpeIsReady = false;
      this.userCreate = {};
      this.goToPaiement = false;
      this.userGroup = {};
    },
    doNotCopyPast(inputId) {
      const myInput = document.getElementById(inputId);
      myInput.onpaste = function (e) {
        e.preventDefault();
      };
      myInput.oncopy = function (e) {
        e.preventDefault();
      };
    },
    async souscrire() {
      this.tpeIsReady = false;
      const loadingComponent = this.$buefy.loading.open();
      this.errorContratAccepte = false;
      this.error = false;
      this.errorSiret = false;
      this.errorEmailFormat = false;
      this.errorEmailUse = false;
      this.errorSamePassword = false;
      if (
        !this.user.civilite ||
        !this.user.nom ||
        !this.user.prenom ||
        !this.user.profession ||
        !this.user.adresse ||
        !this.user.codePostal ||
        !this.user.ville ||
        !this.user.telephone_pro ||
        !this.user.mail ||
        !this.mailCopy ||
        (!this.user.siret && this.siretOrAdeli === "Siret") ||
        (!this.user.adeli && this.siretOrAdeli === "Adeli") ||
        (this.abonnement.engagementAnnuel &&
          !checkAddressInformation(this.abonnement.adresse))
      ) {
        this.error = true;
        loadingComponent.close();
        return;
      }
      if (this.mailCopy !== this.user.mail) {
        this.error = true;
        this.errorSamePassword = true;
        loadingComponent.close();
        return;
      }
      if (this.siretOrAdeli === "Siret") {
        const verificationSiret = await this.verifierSiret();
        if (verificationSiret !== 200) {
          this.errorSiret = true;
          loadingComponent.close();
          return;
        }
      }
      /*if (!this.contratAccepte) {
        this.errorContratAccepte = true;
        loadingComponent.close();
        return;
      }*/
      ParseConfig();
      Parse.masterKey = "myMasterKey";
      if (await Parse.User.current()) {
        Parse.User.logOut();
      }
      if (!this.userCreate.id) {
        const query = new Parse.Query(Parse.User);
        query.equalTo("username", this.user.mail.trim());
        const userResponse = await query.find();
        if (!this.userCreate && userResponse.length) {
          this.error = true;
          this.errorEmailUse = "Email déjà utilisé.";
          loadingComponent.close();
          return;
        }
      }
      try {
        const query = new Parse.Query(Parse.User);
        const UserGroup = Parse.Object.extend("user_group");
        const QueryDomaine = new Parse.Query("domaine");
        QueryDomaine.equalTo("nom", "Santé");
        const maListeDomaine = await QueryDomaine.find();
        let userCreateResponse = null;
        let newUserGroup = null;
        if (!this.userCreate.id) {
          const newUser = new Parse.User();
          createUser(this.user, newUser);
          await newUser.signUp(null);
          query.equalTo("username", this.user.mail.trim());
          userCreateResponse = await query.first();
          newUserGroup = new UserGroup();
        } else {
          userCreateResponse = this.userCreate;
          createUser(this.user, userCreateResponse);
          const QueryUserGroup = new Parse.Query(UserGroup);
          QueryUserGroup.equalTo(
            "objectId",
            this.userCreate.attributes.group.id
          );
          newUserGroup = await QueryUserGroup.first();
        }
        let userGroupName = "";
        if (this.siretOrAdeli === "Siret") {
          userGroupName =
            this.user.nom + "" + this.user.prenom + "" + this.user.siret;
        } else {
          userGroupName =
            this.user.nom + "" + this.user.prenom + "" + this.user.adeli;
        }
        newUserGroup.set("name", userGroupName);
        newUserGroup.set("domaine", maListeDomaine[0]);
        newUserGroup.set("code_affiliation", codeGenerator("xxxxxxxx"));
        newUserGroup.set("subscription_end_date", new Date(2100, 12, 1));
        if (this.abonnement.engagementAnnuel) {
          newUserGroup.set(
            "adresse_livraison_nom",
            this.abonnement.adresse.nom
          );
          newUserGroup.set(
            "adresse_livraison_prenom",
            this.abonnement.adresse.prenom
          );
          newUserGroup.set(
            "adresse_livraison",
            this.abonnement.adresse.adresse
          );
          newUserGroup.set(
            "adresse_livraison_complement",
            this.abonnement.adresse.complementAdresse
          );
          newUserGroup.set(
            "adresse_livraison_code_postal",
            this.abonnement.adresse.codePostal
          );
          newUserGroup.set(
            "adresse_livraison_ville",
            this.abonnement.adresse.ville
          );
          newUserGroup.set(
            "adresse_livraison_code_acces_residence",
            this.abonnement.adresse.codeAcces
          );
        } else {
          newUserGroup.set("adresse_livraison_nom", this.user.lastname);
          newUserGroup.set("adresse_livraison_prenom", this.user.firstname);
          newUserGroup.set("adresse_livraison", this.user.adresse);
          newUserGroup.set(
            "adresse_livraison_complement",
            this.user.complementAdresse
          );
          newUserGroup.set(
            "adresse_livraison_code_postal",
            this.user.codePostal
          );
          newUserGroup.set("adresse_livraison_ville", this.user.ville);
        }
        newUserGroup.set(
          "cartes_postales",
          this.abonnement.engagementAnnuel
            ? this.abonnement.cartesPostales
            : false
        );
        newUserGroup.set(
          "code_postal_cb",
          this.abonnement.informationsBancaires.codePostal
        );
        newUserGroup.set("pays", this.abonnement.informationsBancaires.pays);
        newUserGroup.set(
          "numero_tva",
          this.abonnement.informationsBancaires.numeroTva
        );
        const queryAppParam = new Parse.Query("app_param");
        const appParam = await queryAppParam.first();
        newUserGroup.set("nb_users", appParam.attributes.nbUserParAbonnement);
        newUserGroup.set("compte_valide", false);
        const userGroupResponse = await newUserGroup.save();

        let userCreateSave = null;
        const QueryLinks = new Parse.Query(Parse.Object.extend("link"));
        QueryLinks.equalTo("type_abonnement", "gratuit");
        QueryLinks.limit(1000);
        const ResponseLinks = await QueryLinks.find();

        const QueryLinksPlay = new Parse.Query(Parse.Object.extend("link"));
        QueryLinksPlay.equalTo("type_abonnement", "play");
        QueryLinksPlay.limit(1000);
        const ResponseLinksPlay = await QueryLinksPlay.find();

        const QueryLinksPulse = new Parse.Query(Parse.Object.extend("link"));
        QueryLinksPulse.equalTo("type_abonnement", "pulse");
        QueryLinksPulse.limit(1000);
        const ResponseLinksPulse = await QueryLinksPulse.find();

        const RelationLinkFromUser = userCreateResponse.relation("link");
        ResponseLinks.forEach((link) => {
          RelationLinkFromUser.add(link);
        });
        ResponseLinksPlay.forEach((link) => {
          RelationLinkFromUser.add(link);
        });
        ResponseLinksPulse.forEach((link) => {
          RelationLinkFromUser.add(link);
        });

        if (!this.userCreate.id) {
          userCreateResponse.set("group", userGroupResponse);
          userCreateSave = await userCreateResponse.save(
            {},
            { useMasterKey: true }
          );
          // const userCreateSave = await userCreateResponse.save();
          const rolesQuery = new Parse.Query(Parse.Role);
          rolesQuery.equalTo("name", "Managers");
          const roleResponse = await rolesQuery.first();
          roleResponse.relation("users").add(userCreateSave);
          roleResponse.save({}, { useMasterKey: true });
        } else {
          userCreateSave = await userCreateResponse.save(
            {},
            { useMasterKey: true }
          );
        }
        Parse.User.logOut();
        loadingComponent.close();
        this.hasSubscription = true;
        this.isPaiement = true;

        this.transactionData = {
          email: this.user.mail,
          referenceCode:
            "pulse_" +
            userGroupResponse.id +
            "_" +
            codeGenerator("xxxx") +
            "-therapeute",
        };
        this.userCreate = userCreateSave;
        this.userGroup = userGroupResponse;
        this.editTransactionData(this.abonnement.engagementAnnuel);
        //this.sendMail(newUserResponse);
        this.tpeIsReady = true;
      } catch (e) {
        if (
          e.toString().includes("Account already exists for this username.")
        ) {
          this.errorEmailUse = true;
        } else {
          this.errorEmailFormat = true;
        }
        loadingComponent.close();
      }
    },
    retour() {
      this.hasSubscription = false;
    },
    updateAdresse(e) {
      this.abonnement.adresse = e;
      this.contratAccepte = false;
    },
    async verifierSiret() {
      try {
        const response = await axios.get(URL_API_SIRET + this.user.siret, {
          headers: {
            Authorization: "Bearer ed6237c4-d0c0-3aed-b695-75ff7ddb869e",
          },
        });
        return response.status;
      } catch (error) {
        return error;
      }
    },
    async sendMail(newUserResponse) {
      const passwordGenerate = codeGenerator("xxxxxxxx");
      await Parse.Cloud.run("validationCompteTherapeute", {
        objectId: newUserResponse.id,
        password: passwordGenerate,
      });
    },
    editTransactionData(value) {
      this.transactionData.engagementAnnuel = value;
      if (this.transactionData.engagementAnnuel) {
        this.transactionData.reference =
          this.transactionData.referenceCode + "-engagement";
        this.transactionData.amount = this.pulseAnnuel.toFixed(2);
      } else {
        this.transactionData.reference =
          this.transactionData.referenceCode + "-sansengagement";
        this.transactionData.amount = this.pulseMensuel.toFixed(2);
      }
      this.code_societe = value
        ? CONFIG_PAIEMENT.CODE_SOCIETE_ANNUEL
        : CONFIG_PAIEMENT.CODE_SOCIETE_MENSUEL;
    },
    async generationFacture() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;

      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      today = dd + "/" + mm + "/" + yyyy;

      ParseConfig();
      var QueryFacture = new Parse.Query(Parse.Object.extend("facture"));
      QueryFacture.equalTo("type_abonnement", "modele"); // Permet de récupérer l'image de fond de la facture
      var ResponseFacture = await QueryFacture.first();
      var transactionData = this.transactionData;
      var group = this.userGroup;
      var user = this.userCreate;
      var QueryFactures = new Parse.Query(Parse.Object.extend("facture"));
      QueryFactures.equalTo("user", this.userCreate);
      var ResponseFactures = await QueryFactures.find();

      await asyncForEach(ResponseFactures, async (facture) => {
        if (!facture.attributes.valide) {
          await facture.destroy();
        }
      });

      var init_ref_facture =
        "SC" +
        yyyy.toString().charAt(2) +
        yyyy.toString().charAt(3) +
        "" +
        mm +
        "";
      var QueryAllFactures = new Parse.Query(Parse.Object.extend("facture"));
      QueryAllFactures.startsWith("reference_facture", init_ref_facture);
      var ResponseAllFactures = await QueryAllFactures.find();

      var initReFactureOk = false;
      var number = ResponseAllFactures.length + 1;
      var finalRef = null;
      while (!initReFactureOk) {
        if (number <= 9) {
          finalRef = init_ref_facture + "000" + number.toString();
        } else if (number <= 99) {
          finalRef = init_ref_facture + "00" + number.toString();
        } else if (number <= 999) {
          finalRef = init_ref_facture + "0" + number.toString();
        } else {
          finalRef = init_ref_facture + number.toString();
        }
        var QueryFactureByRefFacture = new Parse.Query(
          Parse.Object.extend("facture")
        );
        QueryFactureByRefFacture.equalTo("reference_facture", finalRef);
        var ResponseFactureByRefFacture = await QueryFactureByRefFacture.find();
        if (!ResponseFactureByRefFacture.length) {
          initReFactureOk = true;
        } else {
          number++;
        }
      }
      let duration = null;
      let dateRenouvellement = null;
      if (transactionData.engagementAnnuel) duration = "years";
      else duration = "months";
      dateRenouvellement = new Date(moment().add(1, duration));
      while (dateRenouvellement < new Date()) {
        dateRenouvellement = new Date(
          moment(dateRenouvellement).add(1, duration)
        );
      }
      this.toDataURL(
        ResponseFacture.attributes.facture._url,
        async function (dataUrl) {
          var doc = new jsPDF({ compress: true });
          var img = new Image();
          img.src = dataUrl;
          doc.addImage(img, "JPEG", 0, 0, 210, 297, undefined, "FAST");
          doc.setFontSize(9);
          var Reference = transactionData.engagementAnnuel
            ? "Annuel"
            : "Mensuel";
          // Références (21 caractères max);
          doc.text(8, 107, "Pulse_" + Reference);
          // Désignation (60 caractères max sur une ligne)
          doc.text(40, 106, "Offre Pulse");
          // Quantité
          doc.text(147, 107, "1");
          // PU HT
          // eslint-disable-next-line
          doc.text(
            158,
            107,
            (
              Math.round((transactionData.amount / 1.2) * 100) / 100
            ).toString() + " EUR"
          );
          // Montant HT
          // eslint-disable-next-line
          doc.text(
            183,
            107,
            (
              Math.round((transactionData.amount / 1.2) * 100) / 100
            ).toString() + " EUR"
          );
          // Total HT
          // eslint-disable-next-line
          doc.text(
            183,
            149,
            (
              Math.round((transactionData.amount / 1.2) * 100) / 100
            ).toString() + " EUR"
          );
          // TVA 20%
          // eslint-disable-next-line
          doc.text(
            183,
            154,
            (
              Math.round(
                (transactionData.amount - transactionData.amount / 1.2) * 100
              ) / 100
            ).toString() + " EUR"
          );
          // Total TTC
          // eslint-disable-next-line
          doc.text(183, 159, transactionData.amount.toString() + " EUR");
          // Montant réglé
          // eslint-disable-next-line
          doc.text(183, 166, transactionData.amount.toString() + " EUR");
          // Net à payer
          // eslint-disable-next-line
          doc.text(183, 171, transactionData.amount.toString() + " EUR");
          // Référence Facture
          doc.text(178, 28, finalRef);
          // Date
          doc.text(178, 33, today);
          // Mode réglement
          doc.text(178, 38, "Carte bancaire");
          // Référence Commande
          doc.text(107, 195.5, transactionData.reference);

          // Profession
          doc.text(5, 65, user.attributes.profession);
          // Nom / Prénom
          // eslint-disable-next-line
          doc.text(
            5,
            70,
            user.attributes.lastname + " " + user.attributes.firstname
          );
          // Adresse de facturation
          doc.text(5, 75, user.attributes.adresse);
          // Complement Adresse
          if (user.attributes.complement_adresse) {
            doc.text(5, 80, user.attributes.complement_adresse);
            // eslint-disable-next-line
            doc.text(
              5,
              85,
              user.attributes.code_postal + " - " + user.attributes.ville
            );
          } else {
            // Code Postal - Ville
            // eslint-disable-next-line
            doc.text(
              5,
              80,
              user.attributes.code_postal + " - " + user.attributes.ville
            );
          }

          doc.setFontSize(7);
          doc.text(45, 109, "Cet abonnement inclut :");
          // eslint-disable-next-line
          doc.text(
            50,
            112,
            "- Accès aux protocoles experts avec questionnaires d’évaluations"
          );
          // eslint-disable-next-line
          doc.text(50, 115, "- 12 licences d’une durée de 1 à 4 mois");
          // eslint-disable-next-line
          doc.text(
            50,
            118,
            "- Tableau de bord de suivi de vos utilisateurs affiliés"
          );
          // eslint-disable-next-line
          doc.text(
            50,
            121,
            "- Création d’actualités personnalisées pour informer vos affiliés"
          );
          // eslint-disable-next-line
          doc.text(
            50,
            124,
            "- Possibilité de percevoir vos rétrocession en euros (par virement)"
          );
          // eslint-disable-next-line
          doc.text(
            50,
            127,
            "- Accès à l’intégralité de la brique de cohérence cardiaque (avec capteur)"
          );
          // eslint-disable-next-line
          doc.text(
            50,
            130,
            "- 1 capteur usb pour la pratique du biofeedback du PC et Mac"
          );
          // Phrase Renouvellement 1
          // eslint-disable-next-line
          doc.text(
            45,
            133,
            "L'abonnement sera renouvelé automatiquement chaque " +
              (transactionData.engagementAnnuel ? "année." : "trimestre.")
          );
          // Phrase Renouvellement 2
          // eslint-disable-next-line
          doc.text(
            45,
            136,
            "(annulation du renouvellement possible jusqu’à un jour ouvré avant l'échéance)."
          );
          // Phrase Renouvellement 3
          // eslint-disable-next-line
          doc.text(
            45,
            139,
            "Prochain renouvellement le " +
              moment(dateRenouvellement).format("DD/MM/YYYY") +
              "."
          );
          var file = doc.output("datauristring");

          //return;
          try {
            var parseFile = new Parse.File("facture.pdf", {
              base64: file.split("base64,")[1],
            });
            var fileSave = await parseFile.save();
            var ObjectFacture = Parse.Object.extend("facture");
            var Facture = new ObjectFacture();
            Facture.set("type_abonnement", "pulse");
            Facture.set("avec_engagement", transactionData.engagementAnnuel);
            Facture.set("user", user);
            Facture.set("facture", fileSave);
            Facture.set("montant", transactionData.amount);
            Facture.set("credit", 0);
            Facture.set("reference_paiement", transactionData.reference);
            Facture.set("reference_facture", finalRef);
            Facture.set("valide", true);
            Facture.set("group", group);
            Facture.save();
          } catch (e) {
            //console.log(e);
            return;
          }
        }
      );
    },
    toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async chargeParametre() {
      ParseConfig();
      var queryAppParam = new Parse.Query(Parse.Object.extend("app_param"));
      var appParam = await queryAppParam.first();
      this.pulseMensuel = appParam.attributes.pulseMensuel;
      this.pulseAnnuel = appParam.attributes.pulseAnnuel;
      this.pulseEconomie = appParam.attributes.pulseEconomie;
    },
  },
  watch: {
    contratAccepte() {
      if (this.contratAccepte) {
        this.souscrire();
      }
    },
    mailCopy() {
      this.errorSamePassword = false;
      if (this.mailCopy !== this.user.mail) {
        this.errorSamePassword = true;
      }
    },
    mailUser() {
      this.user.mail = this.mailUser;
      this.errorSamePassword = false;
      if (this.mailCopy !== this.user.mail) {
        this.errorSamePassword = true;
      }
      this.errorMajuscule = false;
      const regex = /[A-Z]/gm;
      const str = this.user.mail;
      let m;

      while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        // The result can be accessed through the `m`-variable.
        m.forEach(() => {
          this.errorMajuscule = true;
          // eslint-disable-next-line no-console
        });
      }
    },
    goToPaiement() {
      if (this.goToPaiement) {
        this.generationFacture();
      }
    },
  },
  async mounted() {
    await this.chargeParametre();
    if (this.$route.query.validationpaiement) {
      this.$buefy.toast.open({
        duration: 3000,
        message: "Paiement effectué avec succès.",
        type: "is-success",
      });
      router.push("/connexion");
    } else if (this.$route.query.retour) {
      router.push("/inscription-offre-pulse");
      this.$buefy.toast.open({
        duration: 3000,
        message: "Êchec du paiement.",
        type: "is-danger",
      });
    }
  },
};
</script>

<style scoped>

</style>
